














import {Component, Prop, Vue} from "vue-property-decorator";
import DeskRequest from "@/views/viewcomponents/DeskRequest.vue";
import {TicketDTO} from "@shared_vue/openapi/myvaultapi";

@Component({
  components: {DeskRequest}
})

export default class ErpcDashCaseView extends Vue {
  @Prop({default: () => []}) readonly tickets!: TicketDTO[][];

  private emitUp(id: any){
    this.$emit('showThread',id)
  }
}
