



















































































import {vxm} from "@/store";
import {Component, Vue, Watch} from 'vue-property-decorator';
import ContactCard from "@/views/widgets/ContactCard.vue";
import MyProduct from "@/views/widgets/MyProduct.vue";
import SmileyRater from "@/views/widgets/SmileyRater.vue";
import SmileyRater2 from "@/views/widgets/SmileyRater2.vue";
import {Configuration, ProductDTO, ProfileApiFactory} from "@shared_vue/openapi/myvaultapi";
import WaitModal from "@/views/widgets/modals/WaitModal.vue";



@Component({
    components:{
      SmileyRater2,
      SmileyRater,
      MyProduct,
      ContactCard,
      WaitModal

    }
})

export default class MyAccountPage extends Vue {
    private ui = vxm.ui;
    private auth = vxm.auth;
    private profileApi = ProfileApiFactory(<Configuration>{basePath:process.env.VUE_APP_MYVAULT_API_URL},process.env.VUE_APP_MYVAULT_API_URL);
    private productList: ProductDTO[] = [];
    private showWait: boolean = false;
    private waitABit = false;

    private getHelp(){
      // show modal
    }

  @Watch('auth.idToken')
  onIdToken(val: boolean, oldval: boolean) {
    console.log('onidtoken')
    if (this.waitABit){
      this.waitABit=false;
      this.fetchAll();
    }
  }

    mounted(){
      this.showWait = true;
      const outerThis = this;
      //hey don't forget a nice plz wait modal
      //do we want reminders in the store? I'm not really sure
      //wait for valid user if this was refreshed (I do not save token in store)
      if (!vxm.auth.idToken){ //wait a bit in case it is coming
        this.waitABit=true;
      } else {
        this.fetchAll();
      }

    }

    private async fetchAll(){ // we need to abstract this to something
      let myProducts = await this.profileApi.profileMyProducts();
      this.productList = myProducts.data;
      this.showWait=false;
    }

    private baseProduct = {
      id: 0,
      title: 'MyVault Profile',
      description: 'some description',
      image: 'https://via.placeholder.com/150',
      link: '#',
      quantity:1,
      paymentPeriod:'Free',
      price: 0.00,
      formattedPrice: 'R0.00'
    }
  // private myProducts = [
  //   {
  //     id: 1,
  //     title: 'AAA Response Premium',
  //     description: 'some description',
  //     image: 'https://via.placeholder.com/150',
  //     link: '/',
  //     quantity:1,
  //     paymentPeriod:'Per Month',
  //     price: 10.00,
  //     formattedPrice: 'R10.00'
  //   },
  //   {
  //     id: 2,
  //     title: 'Advanced CoDriver Assist',
  //     description: 'some description',
  //     image: 'https://via.placeholder.com/150',
  //     link: '/',
  //     quantity:1,
  //     paymentPeriod:'Per Month',
  //     price: 20.00,
  //     formattedPrice: 'R20.00'
  //   },
  //   {
  //     id: 3,
  //     title: 'Sacvia Basic',
  //     description: 'some description',
  //     image: 'https://via.placeholder.com/150',
  //     link: '/',
  //     quantity:1,
  //     paymentPeriod:'Per Month',
  //     price: 30.00,
  //     formattedPrice: 'R30.00'
  //   },
  //   {
  //     id: 4,
  //     title: 'Sacvia Advanced',
  //     description: 'some description',
  //     image: 'https://via.placeholder.com/150',
  //     link: '/',
  //     quantity:1,
  //     paymentPeriod:'Per Month',
  //     price: 40.00,
  //     formattedPrice: 'R40.00'
  //   }
  //
  // ]

  // private totalSubs = this.myProducts.length;
  // private totalSubsCost = this.myProducts.reduce((a,b)=>a+b.price,0);

  private navigate(where: string){
    this.$router.push(where);
  }

}
