
























































































































































































































































































































































































































































































































































































































































































import Vue from "vue";
import Component from "vue-class-component";
import { vxm } from "@/store";

import ProfileCard from "@/views/viewcomponents/ProfileCard.vue";
import CustomCard from "@/views/viewcomponents/CustomCard.vue";
import SmallCard from "@/views/viewcomponents/SmallCard.vue";
import MicroCard from "@/views/viewcomponents/MicroCard.vue";
import TbEditModal from "../TbEditModal.vue";
import { Watch } from "vue-property-decorator";
import WaitModal from "@/views/widgets/modals/WaitModal.vue";
import {
  Configuration,
  ContactDTO,
  ContactRelationship,
  ContactType, MedicAlertDTO,
  MedicalInfoDTO,
  ProfileApiFactory,
} from "@shared_vue/openapi/myvaultapi";
import DynamicInputModal from "@shared_vue/components/modals/DynamicInputModal.vue";
import { SelectOption } from "@shared_vue/types/SelectOption";

@Component({
  components: {
    WaitModal,
    CustomCard,
    ProfileCard,
    SmallCard,
    MicroCard,
    TbEditModal,
    DynamicInputModal,
  },
})
export default class MyHealthPage extends Vue {
  private theUi = vxm.ui;
  private waitABit = false;
  private profileStore = vxm.profile;
  private showWait: boolean = false;

  private profileApi = ProfileApiFactory(
    <Configuration>{ basePath: process.env.VUE_APP_MYVAULT_API_URL },
    process.env.VUE_APP_MYVAULT_API_URL
  );

  private get contactListNames() {
    return this.contactList?.map((a) => ({
      value: a.id,
      label: a.firstNames + " " + a.lastName
    }));
  }
  get contactList(): ContactDTO[] | undefined {
    return this.profileStore.contactList;
  }

  get medicAlerts(): MedicAlertDTO[] | undefined {
    return this.profileStore.currentProfile.medicalInfo?.medicalAlerts;
  }

  get medicalAidDependents(): ContactDTO[] | undefined {
    return this.contactList?.filter((c) =>
      c.contactType.includes(ContactType.MedicalAidDependent)
    );
  }

  get nextOfKins(): ContactDTO[] | undefined {
    return this.contactList?.filter(c => c.contactType.includes(ContactType.Nextofkin));
  }
  get myMedicalInfo(): MedicalInfoDTO {
    if (this.profileStore.currentProfile.medicalInfo == undefined) {
      this.profileStore.setMedicalInfo(<MedicalInfoDTO>{});
    }
    return this.profileStore.currentProfile.medicalInfo!;
  }

  set myMedicalInfo(newInfo: MedicalInfoDTO) {
    this.profileStore.setMedicalInfo(newInfo);
  }

  get myOwnProfile() {
    return this.profileStore.currentProfile;
  }

  private async saveChanges() {
    console.log("going to save..");
    let saveResult = await this.profileApi.profileUpdateOwnMedicalInfo(
      this.profileStore.myProfile.medicalInfo!
    );
    console.log(saveResult);
  }

  private async fetchContacts() {
    console.log("fetching contacts");
    let contactList = await this.profileApi.profileGetMyContacts();
    console.log("contacts:" + contactList);
    if (
      contactList.status == 200 &&
      contactList.data &&
      contactList.data.length > 0
    ) {
      this.profileStore.setContacts(contactList.data);
    }
  }

  private async addContactToServer(contact: ContactDTO) {
    console.log("going to save..");
    let saveResult = await this.profileApi.profileAddContact(contact);
    console.log(saveResult);
    if (saveResult.status == 200) {
      this.fetchContacts();
    }
  }

  private async addMedicAlertToServer(medAlert: MedicAlertDTO) {
    console.log("going to save..");
    let saveResult = await this.profileApi.profileAddMedicAlert(medAlert);
    console.log(saveResult);
    if (saveResult.status == 200) {
      this.profileStore.setMedicalInfo(saveResult.data);
    }
  }

  private async updateContactOnServer(contact: ContactDTO) {
    console.log("going to save..");
    let saveResult = await this.profileApi.profileUpdateContact(contact);
    console.log(saveResult);
    if (saveResult.status == 200) {
      this.fetchContacts();
    }
  }

  private async updateRelationshipOnServer(contact: ContactRelationship) {
    console.log("going to save..");
    let saveResult = await this.profileApi.profileAddContactRelation(contact)
    console.log(saveResult);
    if (saveResult.status == 200) {
      this.fetchContacts();
    }
  }

  private saveResult(whichPopup: string) {
    console.log(this.$refs.tbEditModal);
    //note this will not automatically update the store image
    var outerthis = this;
    //note, processing is different for the different modals.
    //Ideally we would have one typed modal per entry but that seems slow, I like the way this lets us add dynamic fields
    //However if there is divergence between named fields and the objects it will die. Maybe add some tests

    if (whichPopup === "BloodType" || whichPopup === "PolicyDetails") {
      let copy = outerthis.myMedicalInfo;
      // @ts-ignore
      this.$refs.dynamicModal.el.forEach((e) => {
        console.log(e.key);
        let prop = e.key;
        // @ts-ignore
        copy[prop] = e.value; //this is dangerous tho
      });
      outerthis.profileStore.setMedicalInfo(copy); // try persist to server too
      this.saveChanges();
    }

    if (whichPopup === "DependentNew") {
      let newContact = <ContactDTO>{
        // @ts-ignore
        contactType: [this.$refs.dynamicModal.el.find(x => x.key === 'relationship').value, ContactType.MedicalAidDependent],
        // @ts-ignore
        firstNames: this.$refs.dynamicModal.el.find(x => x.key === 'name').value,
        // @ts-ignore
        lastName: this.$refs.dynamicModal.el.find(x => x.key === 'surname').value,
        // @ts-ignore
        dateOfBirth: this.$refs.dynamicModal.el.find(x => x.key === 'dob').value,
        // @ts-ignore
        phonePrimary: this.$refs.dynamicModal.el.find(x => x.key === 'phone').value,

      }
      console.log('Going to save contact: ' + newContact);
      this.addContactToServer(newContact);
    }

    if (whichPopup === 'NewMedicAlert') {
      let newMedicAlert = <MedicAlertDTO>{
        // @ts-ignore
        title: this.$refs.dynamicModal.el.find(x => x.key === 'title').value,
        // @ts-ignore
        description: this.$refs.dynamicModal.el.find(x => x.key === 'description').value,
        // @ts-ignore
        notes: this.$refs.dynamicModal.el.find(x => x.key === 'notes').value
      }
      this.addMedicAlertToServer(newMedicAlert);
    }

    if (whichPopup === 'NextOfKinNew') {
      let newContact = <ContactDTO>{
        // @ts-ignore
        contactType: [this.$refs.dynamicModal.el.find(x => x.key === 'relationship').value, ContactType.Nextofkin],
        // @ts-ignore
        firstNames: this.$refs.dynamicModal.el.find(x => x.key === 'name').value,
        // @ts-ignore
        lastName: this.$refs.dynamicModal.el.find(x => x.key === 'surname').value,
        // @ts-ignore
        dateOfBirth: this.$refs.dynamicModal.el.find(x => x.key === 'dob').value,
        // @ts-ignore
        phonePrimary: this.$refs.dynamicModal.el.find(x => x.key === 'phone').value,

      }
      // @ts-ignore
      let existingId = this.$refs.dynamicModal.el.find(x => x.key === 'id').value;
      if (existingId) {
        console.log('Existing update...');
        newContact.id = parseInt(existingId);
        //run update not new :-O //we should maybe handle this logic internally on the web service tho
        //add relationship
        let newRelationship = <ContactRelationship>{
          contactType: [ContactType.Nextofkin],
          contactId: existingId,
          profileGuid: this.profileStore.myProfile.guid
        };
        this.updateRelationshipOnServer(newRelationship);
        //add new relation
      } else {
        console.log('Going to save contact: ' + newContact);
        this.addContactToServer(newContact);
      }
    }

    if (whichPopup.startsWith("settings_modal_")) {
      let id = whichPopup.substr(15);
      console.log("updating contact with id: " + id);
      let id2 = parseInt(id);
      let newContact = <ContactDTO>{
        // @ts-ignore
        contactType: [
          // @ts-ignore
          this.$refs.dynamicModal.el.find((x) => x.key === "relationship").value,
        ],
        // @ts-ignore
        firstNames: this.$refs.dynamicModal.el.find((x) => x.key === "name")
          .value,
        // @ts-ignore
        lastName: this.$refs.dynamicModal.el.find((x) => x.key === "surname")
          .value,
        // @ts-ignore
        dateOfBirth: this.$refs.dynamicModal.el.find((x) => x.key === "dob")
          .value,
      };
      newContact.id = id2;
      console.log("Going to save contact: " + newContact);
      this.updateContactOnServer(newContact);
    }
  }

  private contactSelected(which: any) { //which is an event which is annoying
    console.log('chose: ' + which); //reverse search this now
    let id = which.target.value;
    console.log('selecting contact with id: ' + id);
    let actualContact = this.contactList!.find(c => c.id == id);

    //fetch details and populate popup
    // let actualContact = this.contactList!.find(c=>c.id == parseInt(which.value));
    // @ts-ignore
    this.$refs.dynamicModal.el.find((x) => x.key === "id").value = actualContact.id;
    // @ts-ignore
    this.$refs.dynamicModal.el.find((x) => x.key === "surname").value = actualContact.lastName;
    // @ts-ignore
    this.$refs.dynamicModal.el.find((x) => x.key === "name").value = actualContact.firstNames;
    // @ts-ignore
    this.$refs.dynamicModal.el.find((x) => x.key === "dob").value = actualContact.dateOfBirth;
    // @ts-ignore
    this.$refs.dynamicModal.el.find((x) => x.key === "relationship").value = actualContact.contactType.filter(ct => ct !== 'MEDICAL_AID_DEPENDENT')[0];
    // @ts-ignore
    this.$refs.dynamicModal.el.find((x) => x.key === "phone").value = actualContact?.phonePrimary ?? '';

  }

  @Watch("auth.idToken")
  onIdToken(val: boolean, oldval: boolean) {
    console.log("onidtoken");
    if (this.waitABit) {
      this.waitABit = false;
      // this.showWait=false;
    }
  }

  mounted() {
    console.log("mount");
    //hey don't forget a nice plz wait modal
    //do we want reminders in the store? I'm not really sure
    //wait for valid user if this was refreshed (I do not save token in store)
    if (!vxm.auth.idToken) {
      //wait a bit in case it is coming
      // this.waitABit=true;
      // this.showWait = true;
    } else {
      //data already cached
      // this.fetchAll();
    }

    if (!this.profileStore.myProfile.medicalInfo) {
      let newMedicalInfo = <MedicalInfoDTO>{};
      let profile = this.profileStore.myProfile;
      profile.medicalInfo = newMedicalInfo;
      this.profileStore.setProfile(profile);
    }
    this.fetchContacts();
  }

  get existingContacts(): SelectOption[] | undefined {
    return this.contactList?.map((el) => ({ value: el.id!.toString(), label: el.firstNames! }));
  }
  private relationshipOptions: SelectOption[] = [
    { value: "SPOUSE", label: "Spouse" },
    { value: "CHILD", label: "Child" },
    { value: "FATHER", label: "Father" },
    { value: "MOTHER", label: "Mother" },
  ];

  private provinceOptions: SelectOption[] = [
    { value: "KZN", label: "KwaZulu-Natal" },
    { value: "GP", label: "Gauteng" },
    { value: "EC", label: "Eastern Cape" },
    { value: "WC", label: "Western Cape" },
    { value: "NC", label: "Northern Cape" },
    { value: "LM", label: "Limpopo" },
    { value: "MP", label: "Mpumalanga" },
    { value: "FS", label: "Free State" },
    { value: "NW", label: "North West" },
  ];
  private provinceOption: string = "KZN";
}
