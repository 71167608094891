









import {vxm} from "@/store";
import {Component, Vue} from 'vue-property-decorator';
import BackendTable from "@/views/tables/BackendTable.vue";



@Component({
    components: {BackendTable}
})

export default class MyInvoicePage extends Vue {
  private ui = vxm.ui;




}
