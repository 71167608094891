




















































































































































































































































































































































































































































































































































































import { vxm } from "@/store";
import { Component, Vue } from "vue-property-decorator";
import CustomCard from "@/views/viewcomponents/CustomCard.vue";
import SmallCard from "@/views/viewcomponents/SmallCard.vue";
import ProfileCard from "@/views/viewcomponents/ProfileCard.vue";
import TbEditModal from "../TbEditModal.vue";
import DynamicInputModal from "@shared_vue/components/modals/DynamicInputModal.vue";

@Component({
  components: {
    CustomCard,
    SmallCard,
    ProfileCard,
    TbEditModal,
    DynamicInputModal,
  },
})
export default class VehicleCheck extends Vue {
  private ui = vxm.ui;

  private items: any = [
    {
      reg_no: "DBN20001ZA",
      description: "Red Marcedes 5 type, 2018 Model",
      image: require("@/assets/images/car.png"),
    },
    {
      reg_no: "DBN20001ZA",
      description: "Red Marcedes 5 type, 2018 Model",
      image: require("@/assets/images/car.png"),
    },
    {
      reg_no: "DBN20001ZA",
      description: "Red Marcedes 5 type, 2018 Model",
      image: require("@/assets/images/car.png"),
    },
    {
      reg_no: "DBN20001ZA",
      description: "Red Marcedes 5 type, 2018 Model",
      image: require("@/assets/images/car.png"),
    },
    {
      reg_no: "DBN20001ZA",
      description: "Red Marcedes 5 type, 2018 Model",
      image: require("@/assets/images/car.png"),
    },
    {
      reg_no: "DBN20001ZA",
      description: "Red Marcedes 5 type, 2018 Model",
      image: require("@/assets/images/car.png"),
    },
    {
      reg_no: "DBN20001ZA",
      description: "Red Marcedes 5 type, 2018 Model",
      image: require("@/assets/images/car.png"),
    },
    {
      reg_no: "DBN20001ZA",
      description: "Red Marcedes 5 type, 2018 Model",
      image: require("@/assets/images/car.png"),
    },
    {
      reg_no: "DBN20001ZA",
      description: "Red Marcedes 5 type, 2018 Model",
      image: require("@/assets/images/car.png"),
    },
    {
      reg_no: "DBN20001ZA",
      description: "Red Marcedes 5 type, 2018 Model",
      image: require("@/assets/images/car.png"),
    },
  ];
  private fields: any = [
    { key: "reg_no", _style: "text-align:center" },
    { key: "description", _style: "text-align:center" },
    { key: "image", _style: "text-align:center" },
    {
      key: "show_details",
      label: "",
    },
  ];

  private report(item: { reg_no: any }) {
    alert(item.reg_no);
  }
}
