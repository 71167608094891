<template>
<div>
    <span><b>RATE YOUR EXPERIENCE</b></span>
    <CRow>
        <img src="../../assets/images/greensmiley.png" height="70" width="70"/>
        <img src="../../assets/images/orangesmiley.png" height="70" width="70"/>
        <img src="../../assets/images/redsmiley.png" height="70" width="70"/>
    </CRow>
</div>
</template>

<script>
    export default {
        name: "SmileyRater"
    }
</script>

<style scoped>
    img {
        cursor:pointer;
    }
</style>
