import Vue from 'vue'
import App from './App.vue'
import router from './router'
import CoreuiVue from '@coreui/vue/src';
import {iconsSet as icons} from './assets/icons/icons'
import {store} from "@/store";
import {worker} from "@/mocks/browser";
import firebase from "firebase/app";
import "firebase/auth";
import {vxm} from "@/store";
import axios from 'axios';
import VueFilterDateFormat from '@vuejs-community/vue-filter-date-format';
import VueFilterDateParse from '@vuejs-community/vue-filter-date-parse';
import LogRocket from 'logrocket';
import VueMeta from 'vue-meta'
import install from '@shared_vue/components/placesautocomplete/index';


Vue.use(install);
Vue.use(VueMeta, {
  // optional pluginOptions
  refreshOnceOnNavigation: true
})
Vue.use(VueFilterDateParse);
Vue.use(VueFilterDateFormat);
Vue.use(CoreuiVue);

//Vue.config.productionTip = false
// LogRocket.init('l9fvvy/gen11myvault');
// LogRocket.identify('1b6Wfl4ipSaZZqCKDfFFCAU6sEB3', {
//   name: 'Dirk Coetsee',
//   email: 'dirk.coetsee@gmail.com',
//
//   // Add your own custom user variables here, ie:
//   subscriptionType: 'pro'
// });
// if (process.env.NODE_ENV === 'development') {
//   worker.start();
// }

const configOptions = {
  apiKey: "AIzaSyCu1pdi3fAksEqyjXCtBpgRfrAEk-mOnYg",
  authDomain: "trackbox-world.firebaseapp.com",
  databaseURL: "https://trackbox-world.firebaseio.com",
  projectId: "trackbox-world",
  storageBucket: "trackbox-world.appspot.com",
  messagingSenderId: "781096390213",
  appId: "1:781096390213:web:1daf5f647a6ee3d782dd2c",
  measurementId: "G-YJGMTFR2M5"
};

firebase.initializeApp(configOptions);

firebase.auth().onIdTokenChanged(user=> {
  if (user){
    user.getIdToken().then(function(idToken) {  // <------ Check this line
      // console.log(idToken); // It shows the Firebase token now
      // console.log('got a user: ' + user);
      store.commit('auth/setUser',user);
      store.commit('auth/setToken',idToken);
      //send it for a cookie
      /////vxm.auth.cookieMe()
      // vxm.auth.setUser(user);
      // vxm.auth.setToken(idToken);
      // axios.defaults.headers.common = {'Authorization': `Bearer ${idToken}`}
    });
  }
})

axios.defaults.withCredentials = true

axios.interceptors.request.use(async config => {
  // perform a task before the request is sent
  try {
    let token = await firebase.auth().currentUser?.getIdToken();
    if (token) {
      config.headers['Authorization'] = 'Bearer ' + token;
    }
    return config
  } catch (e) {
    return Promise.reject('No token')
  }
})

router.beforeEach((to, from, next) => {
  // console.log('checking if this route is ok...');
  const user = firebase.auth().currentUser;
  const requiresAuth = to.matched.some(record => record.meta.requiresAuth);

  if (requiresAuth && (user==null)) {
    next("/");
  } else if (requiresAuth && user) {
    next();
  } else {
    next();
  }
});

firebase.auth().onAuthStateChanged(user => {
  new Vue({
    router,
    store,
    //CIcon component documentation: https://coreui.io/vue/docs/components/icon
    icons,
    render: h => h(App),
  }).$mount('#app');
  
  if (user) {
    console.log(user); // It shows the Firebase user
    user.getIdToken().then(function(idToken) {  // <------ Check this line
      // console.log(idToken); // It shows the Firebase token now
      // console.log('got a user: ' + user);
      store.commit('auth/setUser',user);
      store.commit('auth/setToken',idToken);
      // vxm.auth.setUser(user);
      // vxm.auth.setToken(idToken);
      // axios.defaults.headers.common = {'Authorization': `Bearer ${idToken}`}
    });
  }
});

