



































import {vxm} from "@/store";
import {Component, Vue, Prop} from 'vue-property-decorator';


@Component
export default class MyProduct extends Vue {
  @Prop(Boolean) readonly noButton: boolean | undefined;
  @Prop(String) readonly imagesrc: string | undefined;
  @Prop(String) readonly title: string | undefined
  @Prop(String) readonly description: string | undefined
  @Prop(Number) readonly productId: number | undefined
  @Prop(Number) readonly quantity: number | undefined
  @Prop(String) readonly formattedPrice: string | undefined
  @Prop(String) readonly paymentPeriod: string | undefined
  @Prop({ default: '/', type: String }) navlink!: string
  private ui = vxm.ui;
  //note these dynamic static assets will likely be served from S3

  private publicPath = process.env.VUE_APP_STATIC_DIR;

  private showDetails(){
    this.$router.push(`/dashboard/myproduct/${this.productId}`);
  }


}
