



























































import {vxm} from "@/store";
import {Component, Vue, Prop} from 'vue-property-decorator';
import ribbon from "@/views/widgets/ribbon.vue";
import {LineItemDTO, ProductDTO} from "@shared_vue/openapi/myvaultapi";


@Component({
  components: {ribbon}
})
export default class ShopProduct extends Vue {
  @Prop()
  readonly product!: ProductDTO;

    private ui = vxm.ui;
    private shop = vxm.shop;
    //note these dynamic static assets will likely be served from S3

    private publicPath = process.env.VUE_APP_STATIC_DIR;

    private showDetails(){
        this.$router.push(`/dashboard/product/${this.product.id}`);
    }

    private addToCart(){
        let lineItem = <LineItemDTO>{product:this.product,quantity:1};
        this.shop.addToCart(lineItem); //api call is handled from store action
        this.$emit('added-product',this.product.title);
    }

}
