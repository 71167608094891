








import {vxm} from "@/store";
import {Component, Vue, Prop} from 'vue-property-decorator';


@Component
export default class ContactCard extends Vue {
    @Prop(String) readonly imagesrc: string | undefined
    @Prop(String) readonly name: string | undefined
    private ui = vxm.ui;
    //note these dynamic static assets will likely be served from S3
    private publicPath = process.env.VUE_APP_STATIC_DIR;
    private getImgUrl(){
        return '';
    }

}
