







import {Component, Vue} from "vue-property-decorator";
import firebase from "firebase";
import {vxm} from "@/store";
import {LoginState} from "@/store/auth/types";
import {Configuration, ProfileApiFactory} from "@shared_vue/openapi/myvaultapi";


@Component({
  components: {}
})

export default class SignInWithEmail extends Vue {
  
  private profileApi = ProfileApiFactory(
      <Configuration>{ basePath: process.env.VUE_APP_MYVAULT_API_URL },
      process.env.VUE_APP_MYVAULT_API_URL
  );

  private profileStore = vxm.profile;
  private showError = false;
  private errorMessage = "ERROR";
  
  private go(){
    const outerThis = this;
    // Confirm the link is a sign-in with email link.
    if (firebase.auth().isSignInWithEmailLink(window.location.href)) {
      // Additional state parameters can also be passed via URL.
      // This can be used to continue the user's intended action before triggering
      // the sign-in operation.
      // Get the email if available. This should be available if the user completes
      // the flow on the same device where they started it.
      var email = window.localStorage.getItem('tempemail');
      if (!email){
        outerThis.errorMessage = "No email found";
        outerThis.showError = true;
        return;
        //we could ask for email? This will happen cross-device
      }
      /*
      if (!email) {
        // User opened the link on a different device. To prevent session fixation
        // attacks, ask the user to provide the associated email again. For example:
        email = window.prompt('Please provide your email for confirmation');
      }*/
      // The client SDK will parse the code from the link for you.
      firebase.auth().signInWithEmailLink(email!, window.location.href)
          .then((result) => {
            // Clear email from storage.
            window.localStorage.removeItem('emailForSignIn');
            // You can access the new user via result.user
            // Additional user info profile not available via:
            // result.additionalUserInfo.profile == null
            // You can check if the user is new or existing:
            // result.additionalUserInfo.isNewUser
            
            //NOT SO FAST... need to check users registration state...
            //really should transform this into async these nested things are annoying
            this.profileApi
                .profileGetProfile()
                .then((resp) => {
                  console.log(resp.data);
                  //add version stuff here :-O
                  if (resp.status == 200 ) {
                    if (resp.data.userStatus == 1) {
                      console.log("ok this user is legit");
                      this.profileStore.setProfile(resp.data);
                      this.$router.push("/dashboard");
                    } else  if (resp.data.userStatus == 0) {
                      console.log("incomplete registration");
                      this.$router.push("/?register=2");
                    }
                  }
                })
                .catch(function (error) {
                  if (error) {
                    console.log(JSON.stringify(error));
                    outerThis.errorMessage = "No email found";
                    outerThis.showError = true;
                  }
                });
            
          })
          .catch((error) => {
            console.log(JSON.stringify(error));
            outerThis.errorMessage = "No email found";
            outerThis.showError = true;
          });
    }
  }
  
  mounted() {
    this.go();
  }
  
}
