import Vue from 'vue';
import Vuex, { StoreOptions } from 'vuex';
import {createProxy, extractVuexModule} from "vuex-class-component/js";
import {UIStore} from "@/store/ui";
import {ShopStore} from "@/store/shop";
import {AuthStore} from "@/store/auth";
import {ProfileStore} from "@/store/profile";
import VuexPersistence from 'vuex-persist'

Vue.use(Vuex);

import localForage from "localforage";


//this is what you crave: https://github.com/localForage/localForage
const storeKey = 'myvaultspastorekey';


const vuexPersist = new VuexPersistence<any>({
  storage: window.localStorage,
  key: storeKey,
  modules:["ui","shop","profile"]
})

export const store = new Vuex.Store({
  modules: {
    ...extractVuexModule( UIStore ),
    ...extractVuexModule( ShopStore ),
    ...extractVuexModule( AuthStore ),
    ...extractVuexModule( ProfileStore ),
  },
  plugins: [vuexPersist.plugin]
})

export const vxm = {
  ui: createProxy( store, UIStore ),
  shop: createProxy( store, ShopStore),
  auth: createProxy(store, AuthStore),
  profile: createProxy(store, ProfileStore)
}
