





















































































import {vxm} from "@/store";
import {Component, Prop, Vue} from 'vue-property-decorator';
import WaitModal from "@/views/widgets/modals/WaitModal.vue";
import BorderlessCard from "@/views/viewcomponents/BorderlessCard.vue";
import CustomCard from "@/views/viewcomponents/CustomCard.vue";

import AssetCard from "@/views/widgets/AssetCard.vue";
import InsuranceCard from "@/views/widgets/InsuranceCard.vue";
import NewAssetModal from "@/views/widgets/modals/NewAssetModal.vue"
import {Configuration, ProfileApiFactory} from "@shared_vue/openapi/myvaultapi";
import {AssetDTO} from "@shared_vue/openapi/myvaultapi";



@Component({
    components: {AssetCard, WaitModal, CustomCard, BorderlessCard, InsuranceCard, NewAssetModal}
})

//the layout for each asset page is the same
//only the content and items differ
//so I think it will also be good to lazy-fetch these instead of cacheing so much data all the time
export default class AssetCategoryPage extends Vue {
  private profileStore = vxm.profile;
  private profileApi = ProfileApiFactory(<Configuration>{basePath:process.env.VUE_APP_MYVAULT_API_URL},process.env.VUE_APP_MYVAULT_API_URL);

    @Prop(String) readonly assetid: string|undefined;

    private title: string = "";
    private blurb: string = "";
    private showWait: boolean = false;
    private showNew: boolean = false;
    private assetList: AssetDTO[] = [];
    private newName: string = '';
    private newDescription: string = '';

    // private description: any={};
    private ui = vxm.ui;
    private auth = vxm.auth;


    mounted(){
      console.log(`asset id: ${this.assetid}`)
        this.assetList=this.profileStore.assetList.filter(a=>a.assetType?.toLowerCase()==this.assetid?.toLowerCase());
        // this.description = this.descriptions.find(a=>a.id==this.assetid);
        this.profileApi.profileGetOptions('AssetType').then((res)=>console.log(res));
    }

    private saveAsset(){
      let newAssetDto = <AssetDTO>{}
      newAssetDto.title=this.newName;
      newAssetDto.description=this.newDescription;
      newAssetDto.assetType=<string>this.assetid;
      newAssetDto.profileId=<string>this.auth.UserId;
      this.profileApi.profilePostAsset(newAssetDto);
    }

    private fetchedDescription =
      {
        title:'My Pets',
        description:"Lorem ipsum dolor sit amet, consectetur adipisicing elit. Animi minus sint voluptatem. Animi\n" +
            "              at, earum eligendi, error, fuga iste minus neque numquam placeat quasi quod velit. Alias aut\n" +
            "              earum eius hic non numquam, vero. Animi asperiores aut beatae consequuntur cum dignissimos\n" +
            "              dolores eaque facilis illo impedit inventore ipsa magnam modi molestiae non odit optio,\n" +
            "              pariatur perspiciatis quis ratione sequi similique vel vero! A ad, aliquam aperiam assumenda\n" +
            "              at aut delectus dolore dolorem ea eius eos eveniet expedita facilis fuga illo illum ipsa\n" +
            "              libero magnam magni minima mollitia nam natus nihil odit omnis, pariatur praesentium quis\n" +
            "              quisquam ratione repellendus rerum sapiente sequi sit tempora, temporibus ullam voluptatum.\n" +
            "              Debitis dignissimos doloribus eveniet expedita",
        imgsrc:'https://via.placeholder.com/150',
      };

    private fetchedInsuranceDetails =
      {
        id:2,
        title:'Insurance Details',
        description:'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Autem beatae error fuga\n' +
            '                            perferendis, provident quibusdam repellat? Ad dolorum ducimus enim, nobis nostrum\n' +
            '                            repudiandae sunt unde vero? Accusamus labore maiores nulla.',
        imgsrc:'star.png',
      }
      private fetchedAssets = [
      {
        id:3,
        title:'Pet1',
        description:'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Autem beatae error fuga\n' +
            '                            perferendis, provident quibusdam repellat? Ad dolorum ducimus enim, nobis nostrum\n' +
            '                            repudiandae sunt unde vero? Accusamus labore maiores nulla.',
        imgsrc:'https://via.placeholder.com/150',
      }
    ];



    private addNew() {
      this.showNew = true;
    }

}
