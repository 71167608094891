import { createModule, mutation, action, extractVuexModule } from "vuex-class-component";

import {RegisterDTO} from "@shared_vue/types/RegisterDTO";

import {LoginState} from "@/store/auth/types";
import {UserApi} from "@shared_vue/services/userservice";
import {AxiosResponse} from "axios";
import {RegisterOTPDTO} from "@shared_vue/types/RegisterOTPDTO";
import {SetPasswordDTO} from "@shared_vue/types/SetPasswordDTO";
import {LoginDTO} from "@shared_vue/types/LoginDTO";
import firebase from "firebase";
import {Configuration as UserConfiguration} from "@shared_vue/openapi/userapi/configuration"
import {
    GlobalAuthData,
    OtpConfirmData,
    RegisterData,
    SetFirstPasswordData,
    UserManagerApiFactory
} from "@shared_vue/openapi/userapi";

const VuexModule = createModule({
  namespaced: "auth",
  strict: false,
})

export class AuthStore extends VuexModule {


    public loginState: LoginState = LoginState.LoggedOut;
    public usr: firebase.User|null=null;
    public idToken: string ='';
    private userApi2 = UserManagerApiFactory(<UserConfiguration>{basePath:process.env.VUE_APP_USER_API_URL},process.env.VUE_APP_USER_API_URL);
    private cookied: boolean = false;

    @mutation setCookieState(val:boolean){
        this.cookied=val;
    }

    @mutation setUser(val:firebase.User){
        this.usr=val;
    }

    @mutation setToken(val:string){
        this.idToken=val;
    }

    @mutation setState(val:LoginState){
        this.loginState=val;
    }
    
    get Cookied() {
        return this.cookied;
    }

    get User() {
        return this.usr;
    }



    get LoggedIn(){
        return (this.usr!=null);
    }

    @action async loginNoRedirect(loginDto: LoginDTO) {
        let result = UserApi.getInstance().loginNoRedirect(loginDto.username, loginDto.password);
        return await result;
    }

    @action async cookieMe() {
        if (this.Cookied){
            console.log('already cookied')
            return;
        }
        console.log('requesting cookie!')
        let result = await this.userApi2.userManagerGlobalAuth(<GlobalAuthData>{token: this.idToken})
        console.log(result);
        if (result.status==200){ //change cookie for token
            console.log('got cookie, exchanging for token...')
            let customToken = await this.userApi2.userManagerGlobalVerifyCookie();
            if (customToken.status==200){
                console.log(customToken.data)
                console.log('got custom token, signing in...');
                this.signInWithCustomToken(customToken.data);
                //will we be stuck in an infinite loop?
            }
        }
    }

    //
    // @action async logout(): Promise<void> {
    //   this.loginRequest();
    //   return this.userManager.signoutRedirect();
    // }
    //
    // @action async getAccessToken(): Promise<string> {
    //   return this.userManager.getUser().then((data: any) => {
    //     return data.access_token;
    //   });
    // }
    @action async setPassword(firstPasswordData: SetFirstPasswordData) {
        console.log('set Password');
        //check if there is no code and then return error straight away
        let result = this.userApi2.userManagerSetFirstPassword(firstPasswordData);

        result
            .then(
                response => {
                    console.log('got response');
                    console.log(response.data);
                    if (response.status == 200) {
                        return new Promise((resolve, reject) => {
                            resolve(response)
                        })
                    } else {
                        return new Promise((resolve, reject) => {
                            reject(response)
                        })
                    }
                },
                error => {
                    console.log('got error');
                    // dispatch('alert/error', error, { root: true });
                    console.log(error);
                    return new Promise((resolve, reject) => {
                        reject(error)
                    })
                }
            );

        return result;
    }


    @action
    async sendOTP(otpData: OtpConfirmData) {
        console.log('sending OTP');
        let result = this.userApi2.userManagerCheckPrimaryOtp(otpData) //UserApi.getInstance().registerOTPStep(otpData);

        result
            .then(
                response => {
                    console.log('got response')
                    console.log(response.data);
                    if (response.status == 200) {
                        return new Promise((resolve, reject) => {
                            resolve(response)
                        })
                    } else {
                        return new Promise((resolve, reject) => {
                            reject(response)
                        })
                    }
                },
                error => {
                    console.log('got error')
                    // dispatch('alert/error', error, { root: true });
                    console.log(error);
                    return new Promise((resolve, reject) => {
                        reject(error)
                    })
                }
            );

        return result;
    }

    @action
    async reSendOTP() {
        console.log('reSending OTP request');
        let result = this.userApi2.userManagerResendOtp() //UserApi.getInstance().registerOTPStep(otpData);

        result
            .then(
                response => {
                    console.log('got response')
                    console.log(response.data);
                    if (response.status == 200) {
                        return new Promise((resolve, reject) => {
                            resolve(response)
                        })
                    } else {
                        return new Promise((resolve, reject) => {
                            reject(response)
                        })
                    }
                },
                error => {
                    console.log('got error')
                    // dispatch('alert/error', error, { root: true });
                    console.log(error);
                    return new Promise((resolve, reject) => {
                        reject(error)
                    })
                }
            );

        return result;
    }

    @action
    public async signInWithCustomToken(token:string){
        let outerThis = this;
        firebase.auth().signInWithCustomToken(token).then(function(res) {
            console.log('all good. Setting state')
            outerThis.setCookieState(true);
        }).catch(function(error) {
            // Handle Errors here.
            var errorCode = error.code;
            var errorMessage = error.message;
            alert('ERROR SIGNING IN: ' + errorMessage)
            // ...
        });
    }

    @action
    async signout(){
        firebase.auth().signOut().then(function() {
            // Sign-out successful.
        }).catch(function(error) {
            // An error happened.
        });
    }

    @action
    async register(registerData: RegisterData): Promise<AxiosResponse<string>> {
        // console.log('gonna register');

        let result = this.userApi2.userManagerRegister(registerData)  //UserApi.getInstance().registerUser(user);
        let outerThis = this;        

        result
            .then(
                response => {
                    // console.log('got response')
                    // console.log(response.data);
                    if (response.status == 200) {
                        //take the token and sign in
                        firebase
                            .auth()
                            .signInWithCustomToken(response.data); //bit dangerous to fire and forget
                        return new Promise((resolve, reject) => {
                            resolve(response)
                        })
                    } else {
                        return new Promise((resolve, reject) => {
                            reject(response)
                        })
                    }
                },
                error => {
                    console.log('got error')
                    // dispatch('alert/error', error, { root: true });                    
                    console.log(error);
                    return new Promise((resolve, reject) => {
                        reject(error)
                    })
                }
            );

        return result;
    }

    get currentLoginState(): LoginState {
        return this.loginState;
    }

    get UserId(): string | undefined {
        return this.usr?.uid;
    }

    get Email(): string | null | undefined {
        return this.usr?.email;
    }

    get DisplayName(): string | null | undefined {
        return this.usr?.displayName;
    }

    get Token(): string | null | undefined {
        return this.idToken;
    }

}
