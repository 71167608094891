import { createModule, mutation, action, extractVuexModule } from "vuex-class-component";
import {AssetDTO, ContactDTO, MedicalInfoDTO, ProfileDTO} from "@shared_vue/openapi/myvaultapi";


const VuexModule = createModule({
    namespaced: "profile",
    strict: false,
})

export class ProfileStore extends VuexModule {

    private readonly version = '0.1';
    public assetList: AssetDTO[] = []
    public myProfile: ProfileDTO = <ProfileDTO>{};

    @mutation setAssets(assets: AssetDTO[]){
        this.assetList = assets;
    }
    
    @mutation setContacts(contacts: ContactDTO[]){
        this.myProfile.contacts = contacts;
    }
    
    @mutation setProfile(newProfile: ProfileDTO){
        this.myProfile = newProfile;
    }
    
    @mutation setMedicalInfo(newInfo: MedicalInfoDTO){
        this.myProfile.medicalInfo = newInfo;
    }

    @action async updateMedicalInfo(newInfo: MedicalInfoDTO){
        this.setMedicalInfo(newInfo);
    }
    
    @action async updateProfile(newProfile: ProfileDTO) { //should I do the save here?
        this.setProfile(newProfile);
    }

    get currentProfile(){
        return this.myProfile;
    }
    
    get contactList(){
        return this.myProfile.contacts;
    }


}
