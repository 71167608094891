import { render, staticRenderFns } from "./MicroCard.vue?vue&type=template&id=702d5557&scoped=true&"
import script from "./MicroCard.vue?vue&type=script&lang=js&"
export * from "./MicroCard.vue?vue&type=script&lang=js&"
import style0 from "./MicroCard.vue?vue&type=style&index=0&id=702d5557&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "702d5557",
  null
  
)

export default component.exports