









































































































































import {vxm} from "@/store";
import {Component, Prop, Vue} from 'vue-property-decorator';
import ProductInfo from "@/views/viewcomponents/ProductInfo.vue";
import WaitModal from "@/views/widgets/modals/WaitModal.vue";
import ShopProduct from "@/views/widgets/ShopProduct.vue";
import ribbon from "@/views/widgets/ribbon.vue";

@Component({
    components: {WaitModal, ProductInfo, ShopProduct, ribbon}
})

export default class ProductPage extends Vue {
    @Prop(Number) readonly productId: number | undefined;

    private imagesrc: string ="";
    private title: string ="";
    private description: string ="";
    private specification: string ="";
    private showWait: boolean = false;
    private showRate: boolean = true;
    private price: string =""; //should this be localised? maybe server-side?
    private rating: string = "";

    private ui = vxm.ui;

    rate(val: string) {
      this.rating = val;
      console.log(this.rating)
    }

    mounted(){
        //fetch data then display
        //just simulating here
        this.showWait=true;
        setTimeout(this.onLoaded, 250);
    }

    private onLoaded(){
        console.log("waited enough...");
        this.showWait=false;
        this.imagesrc='https://via.placeholder.com/250';
        this.title="Product Name Here";
        this.description="lorum dipsum nonsense";
        this.specification="some spec data here";
        this.price="R3.50";
    }
    private recommendedProducts = [
        {
            id: 1,
            title: 'Sample Product',
            description: 'some description',
            image: 'https://via.placeholder.com/150',
            link: '/',
            formattedprice: 'R30.00'
        },
        {
            id: 2,
            title: 'Sample Product',
            description: 'some description',
            image: 'https://via.placeholder.com/150',
            link: '/',
          formattedprice: 'R30.00'
        },
        {
            id: 3,
            title: 'Sample Product',
            description: 'some description',
            image: 'https://via.placeholder.com/150',
            link: '/',
          formattedprice: 'R30.00'
        }
    ]


}
