<template>
    <CCardBody>

        <!--
        dc note: I would like to make some generic, reusable table objects instead of page-specific ones if can be helped
        so we can pass a datasource etc. and options like show/don't show this or that. Styling can be modified at a higher level
        This one is currently very specific but let's see..
        -->
        <!--
          This example simulates data passed lazily from backend.
          This example might be connected to backend, for items lazy loading.
          To integrate with backend delete second CDataTable and implement
          API request in onTableChange() method
          -->
        <CDataTable
                :items="loadedItems"
                :fields="['date', 'reference', 'product', 'type','amount', 'receipt']"
                :table-filter="{ external: true, lazy: true }"
                :table-filter-value.sync="tableFilterValue"
                :sorter="{ external: true, resetable: true }"
                :sorter-value.sync="sorterValue"
                :items-per-page="5"
                :active-page="1"
                hover
                :loading="loading"
        >
            <template #receipt="{item, index}">
                <td class="py-2">
                    <CButton
                            color="primary"
                            variant="outline"
                            square
                            size="lg"
                            @click="showInvoice(item, index)"
                    >
                        Show
                    </CButton>
                </td>
            </template>
        </CDataTable>
        <CPagination
                v-show="pages > 1"
                :pages="pages"
                :active-page.sync="activePage"
        />
        <CDataTable
                class="d-none"
                ref="externalAgent"
                :items="usersData.slice(0)"
                :fields="['date', 'reference', 'product', 'type','amount', 'receipt']"
                :column-filter-value="columnFilterValue"
                :table-filter-value="tableFilterValue"
                :sorter-value="sorterValue"
                :items-per-page="5"
                :active-page="activePage"
                hover
        />
    </CCardBody>
</template>

<script>
    import usersData from './Usersdata'

    export default {
        name: 'BackendTable',
        data () {
            return {
                usersData,
                sorterValue: { column: null, asc: true },
                columnFilterValue: {},
                tableFilterValue: '',
                activePage: 1,
                loadedItems: usersData.slice(0, 5),
                loading: false,
                pages: 5
            }
        },
        watch: {
            reloadParams () {
                this.onTableChange()
            }
        },
        computed: {
            reloadParams () {
                return [
                    this.sorterValue,
                    this.columnFilterValue,
                    this.tableFilterValue,
                    this.activePage
                ]
            }
        },
        methods: {
            onTableChange () {
                this.loading = true
                setTimeout(() => {
                    this.loading = false
                    const agent = this.$refs.externalAgent
                    this.loadedItems = agent.currentItems
                    this.pages = Math.ceil(agent.sortedItems.length / 5)
                }, 1000)
            },
            showInvoice(item, index){
                console.log(item)
                console.log(index)
            }
        }
    }
</script>
