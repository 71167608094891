












































import {vxm} from "@/store";
import {Component, Vue} from 'vue-property-decorator';



import CartLine from "@/views/widgets/CartLine.vue";
import {CartDTO, Configuration, ShopApiFactory} from "@shared_vue/openapi/myvaultapi";
import CheckoutSummary from "@/views/widgets/CheckoutSummary.vue";



@Component({
    components: {CartLine, CheckoutSummary},
})

export default class CartPage extends Vue {
    private ui = vxm.ui;
    private shop = vxm.shop;
  private shopApi = ShopApiFactory(<Configuration>{basePath:process.env.VUE_APP_MYVAULT_API_URL},process.env.VUE_APP_MYVAULT_API_URL);
  private showPaidModal: boolean = false;
  public static syncCarts(cart1: CartDTO, cart2: CartDTO) : CartDTO {
    //note, reconciling versions is kind of hard. I think we will need per-version specific upgrade logic

    //note at the moment I am just replacing contents as merging is kind of complicated.
    //for example if you added items to a basket offline and then fetch an online version that is older
    //it makes sense to replace. To actualy do a merge is kind of airy-fairy and easy to get wrong
    //we can think about it
    console.log('syncing carts');

    if (Date.parse(cart1.updated)>Date.parse(cart2.updated)){
      return cart1;
    } else {
      return cart2;
    }
  }

  private paid(){
    console.log('paid success')
    this.showPaidModal=true;
  }

  private paidDone(){
    this.$router.push('/dashboard/')
  }

    fetchCart(){

        //promise way

        this.shopApi.shopGetOwnCart().then(resp => {
            console.log(resp.data);
            //add version stuff here :-O
            this.shop.replaceCart(CartPage.syncCarts(resp.data,this.shop.GetCart));
        })
            .catch(err => {
                // Handle Error Here
                console.error(err);
            });


    }

    //this logic will be quite common I think we should move it out to the service or somewhere
  created() {
    const outerThis = this;
    //hey don't forget a nice plz wait modal
    //do we want reminders in the store? I'm not really sure
    //wait for valid user if this was refreshed (I do not save token in store)
    if (!vxm.auth.idToken){ //wait a bit in case it is coming.
      console.log('no token. was this a refresh?')
      setTimeout(function() {   //  call a 3s setTimeout when the loop is called
        console.log('timed out');   //  your code here
        if (!vxm.auth.idToken){
          //go back to log in
          console.log('log in again bro')
        } else {
          outerThis.fetchCart();
        }
      }, 2000)
    } else {
      this.fetchCart();
    }
  }

}
