var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"home"},[_c('CRow',[_c('CCol',{staticClass:"mb-3",attrs:{"md":"12"}},[_c('h1',[_vm._v("My Health")])])],1),_c('CRow',[_c('CCol',{staticClass:"pt-3",attrs:{"xl":"4","lg":"6"}},[_c('h5',{staticClass:"mb-3 text-capitalize"},[_c('strong',[_vm._v("Medical Aid Info")])]),_c('CButton',{on:{"click":function($event){return _vm.$refs.dynamicModal.showModal(
          [
            {
              key: 'medicalAidProvider',
              placeHolder: 'Provider',
              value: _vm.myMedicalInfo.medicalAidProvider,
              inputType: 'default',
            },
            {
              key: 'medicalAidNumber',
              placeHolder: 'Policy Number',
              value: _vm.myMedicalInfo.medicalAidNumber,
            },
            {
              key: 'medicalAidPlan',
              placeHolder: 'Policy Name',
              value: _vm.myMedicalInfo.medicalAidPlan,
            },
            {
              key: 'principalMember',
              label: 'Principal Member',
              value: _vm.myMedicalInfo.principalMember,
              inputType: 'checkbox',
            },
            {
              key: 'brokerName',
              placeHolder: 'Broker Name',
              value: _vm.myMedicalInfo.brokerName,
            },
            {
              key: 'brokerPhone',
              placeHolder: 'Broker Tel',
              value: _vm.myMedicalInfo.brokerPhone,
            },
            {
              key: 'everHadHeartAttack',
              label: 'Ever had a heart attack?',
              value: _vm.myMedicalInfo.everHadHeartAttack,
              inputType: 'checkbox',
            } ],
          null,
          'PolicyDetails'
        )}}},[_vm._v("Edit")]),_c('CRow',{staticClass:"mt-3 pt-3"},[_c('CCol',{attrs:{"xl":"3","lg":"4","col":"6"}},[_c('MicroCard',{staticClass:"my-health-cards",attrs:{"title":"Provider","description":"Medial Aid Connect Me"},scopedSlots:_vm._u([{key:"body",fn:function(){return [_c('p',{staticClass:"text-gray"},[_vm._v(" "+_vm._s(_vm.myMedicalInfo.medicalAidProvider)+" ")])]},proxy:true}])})],1),_c('CCol',{attrs:{"xl":"3","lg":"4","col":"6"}},[_c('MicroCard',{attrs:{"title":"Policy Number"},scopedSlots:_vm._u([{key:"body",fn:function(){return [_c('p',{staticClass:"text-gray"},[_vm._v(" "+_vm._s(_vm.myMedicalInfo.medicalAidNumber)+" ")])]},proxy:true}])})],1),_c('CCol',{attrs:{"xl":"3","lg":"4","col":"6"}},[_c('MicroCard',{attrs:{"title":"Policy Name"},scopedSlots:_vm._u([{key:"body",fn:function(){return [_c('p',[_vm._v(_vm._s(_vm.myMedicalInfo.medicalAidPlan))])]},proxy:true}])})],1),_c('CCol',{attrs:{"xl":"3","lg":"4","col":"6"}},[_c('MicroCard',{attrs:{"title":"Broker","description":"Broker Connect Me"},scopedSlots:_vm._u([{key:"body",fn:function(){return [_c('div',{staticStyle:{"text-align":"center"}},[_c('p',{staticStyle:{"display":"block"}},[_vm._v(_vm._s(_vm.myMedicalInfo.brokerName))]),_c('p',{staticStyle:{"display":"block"}},[_vm._v(_vm._s(_vm.myMedicalInfo.brokerPhone))])])]},proxy:true}])})],1)],1),_c('h5',{staticClass:"mt-3 text-capitalize"},[_vm._v("Your medical aid dependants")]),_c('CButton',{on:{"click":function($event){_vm.$refs.dynamicModal.showModal(
          [
            {
              label: 'Relationship',
              key: 'relationship',
              placeHolder: 'Please Select',
              inputType: 'select',
              selectOptions: _vm.relationshipOptions,
            },
            {
              label: 'Name',
              key: 'name',
              placeHolder: 'Name',
              value: '',
            },
            {
              label: 'Surname',
              key: 'surname',
              placeHolder: 'Surname',
              value: '',
            },
            {
              key: 'dob',
              label: 'Date of Birth',
              value: '',
              inputType: 'date',
            },
            {
              key: 'phone',
              label: 'Cell (optional)',
              value: '',
            } ],
          {
            deleteVisible: false,
          },
          'DependentNew'
        )}}},[_vm._v("Add new")]),_c('CRow',{staticClass:"mt-4"},_vm._l((_vm.medicalAidDependents),function(contact){return _c('CCol',{key:contact.id,attrs:{"md":"4","col":"6"}},[_c('ProfileCard',{key:contact.id,attrs:{"description":contact.firstNames + ' ' + contact.lastName},scopedSlots:_vm._u([{key:"body",fn:function(){return [_c('CImg',{attrs:{"fluid":"","thumbnail":true,"src":require('@/assets/images/profile-dummy3-300x300.png'),"alt":""}})]},proxy:true}],null,true)}),_c('CButton',{key:contact.id + '_but',staticClass:"settings-icon",on:{"click":function($event){_vm.$refs.dynamicModal.showModal(
              [
                {
                  key: 'relationship',
                  placeHolder: 'Relationship',
                  value: contact.contactType.filter(
                    function (ct) { return ct !== 'MEDICAL_AID_DEPENDENT'; }
                  )[0],
                  placeHolder: 'Please Select',
                  inputType: 'select',
                  selectOptions: _vm.relationshipOptions,
                  disabled: true,
                },
                {
                  key: 'name',
                  placeHolder: 'Name',
                  value: contact.firstNames,
                },
                {
                  key: 'surname',
                  placeHolder: 'Surname',
                  value: contact.lastName,
                },
                {
                  key: 'dob',
                  placeHolder: 'Date of Birth',
                  inputType: 'date',
                  value: contact.dateOfBirth,
                } ],
              {
                deleteVisible: true,
              },
              'settings_modal_' + contact.id
            )}}},[_c('CIcon',{staticClass:"icon-large",attrs:{"name":"cil-settings"}})],1)],1)}),1),_c('h5',{staticClass:"mt-5 text-capitalize"},[_vm._v("medical history overview")]),_c('ul',{staticClass:"history"},[_c('li',[_c('span',[_vm._v("2010-02-19")]),_vm._v(" → Heart Attack")]),_c('li',[_c('span',[_vm._v("2010-02-19")]),_vm._v(" → Heart Attack")]),_c('li',[_c('span',[_vm._v("2010-02-19")]),_vm._v(" → Heart Attack")]),_c('li',[_c('span',[_vm._v("2010-02-19")]),_vm._v(" → Heart Attack")]),_c('li',[_c('span',[_vm._v("2010-02-19")]),_vm._v(" → Heart Attack")]),_c('li',[_c('span',[_vm._v("2010-02-19")]),_vm._v(" → Heart Attack")])])],1),_c('CCol',{staticClass:"pt-3",attrs:{"xl":"4","lg":"6"}},[_c('h5',{staticClass:"mb-3 text-capitalize"},[_vm._v("Basic Information")]),_c('CButton',{on:{"click":function($event){return _vm.$refs.dynamicModal.showModal(
          [
            {
              key: 'bloodType',
              placeHolder: 'Blood type',
              value: _vm.myMedicalInfo.bloodType,
            },
            {
              key: 'height',
              placeHolder: 'Height',
              value: _vm.myMedicalInfo.height,
            },
            {
              key: 'weight',
              placeHolder: 'Weight',
              value: _vm.myMedicalInfo.weight,
            },
            {
              key: 'medicalHistory',
              placeHolder: 'Medical history',
              value: _vm.myMedicalInfo.medicalHistory,
            },
            {
              key: 'biologicalGender',
              placeHolder: 'Biological gender',
              value: _vm.myMedicalInfo.biologicalGender,
            } ],
          null,
          'BloodType'
        )}}},[_vm._v("Edit")]),_c('CRow',{staticClass:"medicalAidInfo pt-3",staticStyle:{"background-color":"#dedede"}},[_c('CCol',{staticClass:"px-1"},[_c('MicroCard',{attrs:{"title":"Blood Type"},scopedSlots:_vm._u([{key:"body",fn:function(){return [_c('p',[_vm._v(_vm._s(_vm.myMedicalInfo.bloodType))])]},proxy:true}])})],1),_c('CCol',{staticClass:"px-1"},[_c('MicroCard',{attrs:{"title":"Height"},scopedSlots:_vm._u([{key:"body",fn:function(){return [_c('p',[_vm._v(_vm._s(_vm.myMedicalInfo.height))])]},proxy:true}])})],1),_c('CCol',{staticClass:"px-1"},[_c('MicroCard',{attrs:{"title":"Weight"},scopedSlots:_vm._u([{key:"body",fn:function(){return [_c('p',[_vm._v(_vm._s(_vm.myMedicalInfo.weight))])]},proxy:true}])})],1),_c('CCol',{staticClass:"px-1"},[_c('MicroCard',{attrs:{"title":"Heart Attack"},scopedSlots:_vm._u([{key:"body",fn:function(){return [_c('h3',[(_vm.myMedicalInfo.everHadHeartAttack)?_c('div',[_vm._v("✔")]):_c('div',[_vm._v("✘")])])]},proxy:true}])})],1),_c('CCol',{staticClass:"px-1"},[_c('MicroCard',{attrs:{"title":"Biological Gender"},scopedSlots:_vm._u([{key:"body",fn:function(){return [_c('p',[_vm._v(_vm._s(_vm.myMedicalInfo.biologicalGender))])]},proxy:true}])})],1)],1),_c('h5',{staticClass:"mt-3 text-capitalize"},[_vm._v("Medical alert")]),_c('CButton',{on:{"click":function($event){return _vm.$refs.dynamicModal.showModal([
          {
            key: 'title',
            placeHolder: 'Title',
            value: '',
          },
          {
            key: 'description',
            placeHolder: 'Description',
            value: '',
          },
          {
            key: 'notes',
            placeHolder: 'Notes',
            value: '',
          } ], {}, 'NewMedicAlert')}}},[_vm._v("Add new")]),_c('CRow',{staticClass:"mt-3"},_vm._l((_vm.medicAlerts),function(medAlert){return _c('CCol',{key:medAlert.id,staticClass:"px-1",attrs:{"md":"4","col":"6"}},[_c('CustomCard',{attrs:{"src":'star.png',"title":"Medical Affilation","showButton":false},scopedSlots:_vm._u([{key:"description",fn:function(){return [_c('div',{staticClass:"medic-list"},[_c('CListGroup',[_c('CListGroupItem',{staticClass:"p-1",attrs:{"href":"#","color":"default"}},[_c('CImg',{attrs:{"src":'https://via.placeholder.com/25',"width":"25px","height":"25px","thumbnail":true}}),_c('span',{staticClass:"pl-2"},[_vm._v(_vm._s(medAlert.title))])],1),_c('CListGroupItem',{staticClass:"p-1",attrs:{"href":"#","color":"default"}},[_c('CImg',{attrs:{"src":'https://via.placeholder.com/25',"width":"25px","height":"25px","thumbnail":true}}),_c('span',{staticClass:"pl-2"},[_vm._v(_vm._s(medAlert.description))])],1)],1)],1)]},proxy:true}],null,true)}),_c('CButton',{staticClass:"settings-icon",on:{"click":function($event){return _vm.$refs.tbEditModal.showModal(
              [
                {
                  key: 'title',
                  placeHolder: 'Title',
                  value: '',
                },
                {
                  key: 'description',
                  placeHolder: 'Description',
                  value: '',
                },
                {
                  key: 'notes',
                  placeHolder: 'Notes',
                  value: '',
                } ],
              {
                deleteVisible: true,
              }
            )}}},[_c('CIcon',{staticClass:"icon-large",attrs:{"name":"cil-settings"}})],1)],1)}),1)],1),_c('CCol',{staticClass:"p-3",attrs:{"xl":"4","lg":"6"}},[_c('h5',{staticClass:"mb-3 text-capitalize"},[_vm._v("next of kin")]),_c('CButton',{on:{"click":function($event){_vm.$refs.dynamicModal.showModal([
          {
            label: '',
            key: 'id',
            placeHolder: '',
            inputType: 'hidden',
          },
          {
            label: 'Existing?',
            key: 'existing',
            placeHolder: 'None',
            inputType: 'select',
            selectOptions: _vm.existingContacts,
            on: {
              input: function (e) { _vm.contactSelected(e) },
            }
          },
          {
            label: 'Relationship',
            key: 'relationship',
            placeHolder: 'Please Select',
            inputType: 'select',
            selectOptions: _vm.relationshipOptions
          },
          {
            label: 'Name',
            key: 'name',
            placeHolder: 'Name',
            value: '',
          },
          {
            label: 'Surname',
            key: 'surname',
            placeHolder: 'Surname',
            value: ''
          },
          {
            key: 'dob',
            label: 'Date of Birth',
            value: '',
            inputType: 'date'
          },
          {
            key: 'phone',
            label: 'Cell (optional)',
            value: '',
          } ], {
          deleteVisible: false,
        }, 'NextOfKinNew')}}},[_vm._v("Add new")]),_c('CRow',_vm._l((_vm.nextOfKins),function(contact){return _c('CCol',{key:contact.id,attrs:{"md":"4","col":"6"}},[_c('ProfileCard',{key:contact.id,attrs:{"description":contact.firstNames + ' ' + contact.lastName},scopedSlots:_vm._u([{key:"body",fn:function(){return [_c('CImg',{attrs:{"fluid":"","thumbnail":true,"src":require('@/assets/images/profile-dummy3-300x300.png'),"alt":""}})]},proxy:true}],null,true)}),_c('CButton',{key:contact.id + '_but',staticClass:"settings-icon",on:{"click":function($event){_vm.$refs.dynamicModal.showModal(
              [
                {
                  key: 'relationship',
                  placeHolder: 'Relationship',
                  value: contact.contactType.filter(function (ct) { return ct !== 'MEDICAL_AID_DEPENDENT'; })[0],
                  placeHolder: 'Please Select',
                  inputType: 'select',
                  selectOptions: _vm.relationshipOptions,
                  disabled: true
                },
                {
                  key: 'name',
                  placeHolder: 'Name',
                  value: contact.firstNames,
                },
                {
                  key: 'surname',
                  placeHolder: 'Surname',
                  value: contact.lastName,
                },
                {
                  key: 'dob',
                  placeHolder: 'Date of Birth',
                  inputType: 'date',
                  value: contact.dateOfBirth,
                } ],
              {
                deleteVisible: true,
              },
              'settings_modal_' + contact.id
            )}}},[_c('CIcon',{staticClass:"icon-large",attrs:{"name":"cil-settings"}})],1)],1)}),1),_c('h5',{staticClass:"mt-4 text-capitalize"},[_vm._v(" medical practionar/service providers ")]),_c('CButton',{on:{"click":function($event){return _vm.$refs.tbEditModal.showModal([
          {
            key: 'Name',
            placeHolder: 'Name',
            value: '',
          },
          {
            key: 'phonenumber',
            placeHolder: 'Phone number',
            value: '',
          } ])}}},[_vm._v("Add new")]),_c('CRow',{staticClass:"mt-4"},[_c('CCol',{attrs:{"md":"4","col":"6"}},[_c('ProfileCard',{attrs:{"description":"ABC Hospital"},scopedSlots:_vm._u([{key:"body",fn:function(){return [_c('CImg',{attrs:{"fluid":"","thumbnail":true,"src":require('@/assets/images/logo-demo3.png'),"alt":""}})]},proxy:true}])}),_c('CButton',{staticClass:"settings-icon",on:{"click":function($event){return _vm.$refs.tbEditModal.showModal(
              [
                {
                  key: 'Name',
                  placeHolder: 'Name',
                  value: '',
                },
                {
                  key: 'phonenumber',
                  placeHolder: 'Phone number',
                  value: '',
                } ],
              {
                deleteVisible: true,
              }
            )}}},[_c('CIcon',{staticClass:"icon-large",attrs:{"name":"cil-settings"}})],1)],1),_c('CCol',{attrs:{"md":"4","col":"6"}},[_c('ProfileCard',{attrs:{"description":"ABC Hospital"},scopedSlots:_vm._u([{key:"body",fn:function(){return [_c('CImg',{attrs:{"fluid":"","thumbnail":true,"src":require('@/assets/images/logo-demo3.png'),"alt":""}})]},proxy:true}])}),_c('CButton',{staticClass:"settings-icon",on:{"click":function($event){return _vm.$refs.tbEditModal.showModal(
              [
                {
                  key: 'Name',
                  placeHolder: 'Name',
                  value: '',
                },
                {
                  key: 'phonenumber',
                  placeHolder: 'Phone number',
                  value: '',
                } ],
              {
                deleteVisible: true,
              }
            )}}},[_c('CIcon',{staticClass:"icon-large",attrs:{"name":"cil-settings"}})],1)],1),_c('CCol',{attrs:{"md":"4","col":"6"}},[_c('ProfileCard',{attrs:{"description":"ABC Hospital"},scopedSlots:_vm._u([{key:"body",fn:function(){return [_c('CImg',{attrs:{"fluid":"","thumbnail":true,"src":require('@/assets/images/logo-demo3.png'),"alt":""}})]},proxy:true}])}),_c('CButton',{staticClass:"settings-icon",on:{"click":function($event){return _vm.$refs.tbEditModal.showModal(
              [
                {
                  key: 'Name',
                  placeHolder: 'Name',
                  value: '',
                },
                {
                  key: 'phonenumber',
                  placeHolder: 'Phone number',
                  value: '',
                } ],
              {
                deleteVisible: true,
              }
            )}}},[_c('CIcon',{staticClass:"icon-large",attrs:{"name":"cil-settings"}})],1)],1),_c('CCol',{attrs:{"md":"4","col":"6"}},[_c('ProfileCard',{attrs:{"description":"ABC Hospital"},scopedSlots:_vm._u([{key:"body",fn:function(){return [_c('CImg',{attrs:{"fluid":"","thumbnail":true,"src":require('@/assets/images/logo-demo3.png'),"alt":""}})]},proxy:true}])}),_c('CButton',{staticClass:"settings-icon",on:{"click":function($event){return _vm.$refs.tbEditModal.showModal(
              [
                {
                  key: 'Name',
                  placeHolder: 'Name',
                  value: '',
                },
                {
                  key: 'phonenumber',
                  placeHolder: 'Phone number',
                  value: '',
                } ],
              {
                deleteVisible: true,
              }
            )}}},[_c('CIcon',{staticClass:"icon-large",attrs:{"name":"cil-settings"}})],1)],1),_c('CCol',{attrs:{"md":"4","col":"6"}},[_c('ProfileCard',{attrs:{"description":"ABC Hospital"},scopedSlots:_vm._u([{key:"body",fn:function(){return [_c('CImg',{attrs:{"fluid":"","thumbnail":true,"src":require('@/assets/images/logo-demo3.png'),"alt":""}})]},proxy:true}])}),_c('CButton',{staticClass:"settings-icon",on:{"click":function($event){return _vm.$refs.tbEditModal.showModal(
              [
                {
                  key: 'Name',
                  placeHolder: 'Name',
                  value: '',
                },
                {
                  key: 'phonenumber',
                  placeHolder: 'Phone number',
                  value: '',
                } ],
              {
                deleteVisible: true,
              }
            )}}},[_c('CIcon',{staticClass:"icon-large",attrs:{"name":"cil-settings"}})],1)],1),_c('CCol',{attrs:{"md":"4","col":"6"}},[_c('ProfileCard',{attrs:{"description":"ABC Hospital"},scopedSlots:_vm._u([{key:"body",fn:function(){return [_c('CImg',{attrs:{"fluid":"","thumbnail":true,"src":require('@/assets/images/logo-demo3.png'),"alt":""}})]},proxy:true}])}),_c('CButton',{staticClass:"settings-icon",on:{"click":function($event){return _vm.$refs.tbEditModal.showModal(
              [
                {
                  key: 'Name',
                  placeHolder: 'Name',
                  value: '',
                },
                {
                  key: 'phonenumber',
                  placeHolder: 'Phone number',
                  value: '',
                } ],
              {
                deleteVisible: true,
              }
            )}}},[_c('CIcon',{staticClass:"icon-large",attrs:{"name":"cil-settings"}})],1)],1)],1)],1)],1),_c('TbEditModal',{ref:"tbEditModal",on:{"saveclicked":function($event){return _vm.saveResult($event)}}}),_c('DynamicInputModal',{ref:"dynamicModal",on:{"saveclicked":function($event){return _vm.saveResult($event)}}}),_c('WaitModal',{attrs:{"title":"Loading...","showvar":_vm.showWait},on:{"update:showvar":function($event){_vm.showWait=$event}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }