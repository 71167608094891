<template>
  <div class="text-center">
    <h5 class="f-weight-700">Rate This Product</h5>
    <div class="d-flex">
      <input type="radio"
             v-model="rating"
             id="good"
             class="d-none"
             value="good"
             @input="rate($event.target.value)"
      >
      <label for="good">
        <CImg :src="require('@/assets/images/greensmiley.png')"
              width="90%"
              alt=""
              class="m-1 cursor-pointer"
        >

        </CImg>
      </label>
      <input type="radio"
             v-model="rating"
             id="medium"
             class="d-none"
             value="medium"
             @input="rate($event.target.value)"
      >
      <label for="medium">
        <CImg :src="require('@/assets/images/orangesmiley.png')"
              width="90%"
              alt=""
              class="m-1 cursor-pointer"
        >

        </CImg>
      </label>
      <input type="radio"
             v-model="rating"
             id="bad"
             class="d-none"
             value="bad"
             @input="rate($event.target.value)"
      >
      <label for="bad">
        <CImg :src="require('@/assets/images/redsmiley.png')"
              width="90%"
              alt=""
              class="m-1 cursor-pointer"
        >

        </CImg>
      </label>

    </div>
    <div>
      <i>*Only visible if you purchase the product before</i>
    </div>
  </div>
</template>

<script>
export default {
  name: "SmileyRater2",
  data() {
    return {
      rating: null,
    }
  },
  methods: {
    rate(val) {
      this.rating = val;
      console.log(this.rating)
    }
  }
}
</script>

<style lang="scss">
input[type="radio"] + label {
  border-bottom: 6px solid white;
}

input[type="radio"]:checked + label {
  border-bottom: 6px solid #ff7f00;
}
</style>
