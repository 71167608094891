

























































import {vxm} from "@/store";
import {Component, Vue, Prop} from 'vue-property-decorator';
import {AssetDTO} from "@shared_vue/openapi/myvaultapi";


@Component
export default class AssetCard extends Vue {
  @Prop(Number) readonly id: number | undefined;
  @Prop(String) readonly src: string | undefined;
  @Prop({default: 'true'}) readonly showButton : boolean | undefined;
  @Prop() readonly asset: AssetDTO | undefined;
  @Prop(String) readonly btnText: string | undefined;
  @Prop() readonly buttonFunction: Function | undefined;
  private ui = vxm.ui;
  private expanded = false;
  //note these dynamic static assets will likely be served from S3

  private publicPath = process.env.VUE_APP_STATIC_DIR;

  private expand(){
    if (this.buttonFunction) //rather use emit?
      this.buttonFunction(this.id);
    this.expanded = !this.expanded;
  }
private save(){
  this.expanded = !this.expanded;
}

}
