










































import {vxm} from "@/store";
import {Component, Vue, Prop} from 'vue-property-decorator';
import SmileyRater from "@/views/widgets/SmileyRater.vue";
@Component({
    components: {SmileyRater}
})
export default class ProductInfo extends Vue {
    @Prop(Number) readonly productId: number | undefined;
    @Prop(String) readonly imagesrc: string | undefined;
    @Prop(String) readonly price: string | undefined;
    @Prop(String) readonly title: string | undefined
    @Prop(String) readonly description: string | undefined
    @Prop(String) readonly specification: string | undefined

    @Prop({ default: '/', type: String }) navlink!: string
    private ui = vxm.ui;
    //note these dynamic static assets will likely be served from S3


}
