<template>
    <div class="small-card">

        <CCard class="mb-0">
            <CCardHeader>
                <b>
                    <slot name="title">
                        {{ title }}
                    </slot>
                </b>
            </CCardHeader>
            <CCardBody class="p-0" :class="extraBodyClass">
                <slot name="body">
                    This content is in card body component.
                </slot>
            </CCardBody>
        </CCard>
        <p>
            <slot name="description">
                <small>{{ description }}</small>
            </slot>
        </p>
    </div>

</template>

<script>
export default {
    name: "SmallCard",
    props: {
        title: {
            type: String,
            default: ''
        },
        description: {
            type: String,
            default: ''
        },
        extraBodyClass: {
            type: String,
            default: ''
        }
    }
}
</script>

<style scoped lang="scss">
.small-card {
    .card {
        .card-body {
            height: 100px;
            display: flex;
            justify-content: center;
            align-items: center;
        }

        .card-header {
            vertical-align: middle;
            display: flex;
            align-items: center;
            justify-content: center;
            height: 60px;
            padding: 5px;
            text-align: center;
        }
    }
}
</style>
