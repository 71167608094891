const usersData = [
    { date: '2020/01/01', reference: 'abcdef', product: 'AAA Premium', type: 'Invoice', amount:'R 100.00'},
    { date: '2020/02/01', reference: 'abcdefg', product: 'AAA Premium', type: 'Invoice', amount:'R 100.00'},
    { date: '2020/03/01', reference: 'abcdefh', product: 'AAA Premium', type: 'Invoice', amount:'R 100.00'},
    { date: '2020/04/01', reference: 'abcdefi', product: 'AAA Premium', type: 'Invoice', amount:'R 100.00'},


]

export default usersData


