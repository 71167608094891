














































import {vxm} from "@/store";
import {Component, Vue, Prop, PropSync} from 'vue-property-decorator';
import {CartProductDto, LineItemDTO} from "@shared_vue/openapi/myvaultapi";


@Component
export default class CartLine extends Vue {
    @Prop(String) readonly imagesrc: string | undefined;
    @Prop(String) readonly title: string | undefined
    @Prop(String) readonly description: string | undefined
    @Prop(Number) readonly productId: number | undefined
    @Prop() readonly lineItem: LineItemDTO | undefined
    @PropSync('quantity', { type: Number }) syncedQuant!: number;
    @Prop() readonly product: CartProductDto | undefined
    private ui = vxm.ui;
    private shop = vxm.shop;
    //note these dynamic static assets will likely be served from S3

    private publicPath = process.env.VUE_APP_STATIC_DIR;

    private showDetails(){
        this.$router.push(`/dashboard/product/${this.productId}`);
    }

    //NB this removed from the store. We need hooks in the action
    //to remove from the backend
    private removeMe(){
      if (this.lineItem!=undefined) {
        this.shop.deleteLine(this.lineItem);
      }
    }

    private get formattedPrice(){
      let total = this.shop.SubscriptionSum;
      let formatter = new Intl.NumberFormat('en-ZA', {
        style: 'currency',
        currency: 'ZAR',
      });
      return formatter.format(<number>this.product?.price);
    }



  onChange(event:any) {
    let line = this.shop.cart.lines.find(a=>a.product.id==<number>this.productId);
    if (line) {
      line.quantity = +event.target.value;
      this.shop.alterQuantity(line); //the reason for doing this "the long way" is to add WS hooks in the store
    }
  }


}
