/* tslint:disable */
/* eslint-disable */
/**
 * My Title
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface Activity
 */
export interface Activity {
    /**
     * 
     * @type {string}
     * @memberof Activity
     */
    type?: string;
    /**
     * 
     * @type {number}
     * @memberof Activity
     */
    confidence?: number;
}
/**
 * 
 * @export
 * @interface AddressDTO
 */
export interface AddressDTO {
    /**
     * 
     * @type {number}
     * @memberof AddressDTO
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof AddressDTO
     */
    addressTypeId?: string;
    /**
     * 
     * @type {string}
     * @memberof AddressDTO
     */
    ownerGuid?: string;
    /**
     * 
     * @type {string}
     * @memberof AddressDTO
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof AddressDTO
     */
    unitNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof AddressDTO
     */
    complexName?: string;
    /**
     * 
     * @type {string}
     * @memberof AddressDTO
     */
    streetNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof AddressDTO
     */
    streetName?: string;
    /**
     * 
     * @type {string}
     * @memberof AddressDTO
     */
    suburb?: string;
    /**
     * 
     * @type {string}
     * @memberof AddressDTO
     */
    city?: string;
    /**
     * 
     * @type {string}
     * @memberof AddressDTO
     */
    provinceId?: string;
    /**
     * 
     * @type {string}
     * @memberof AddressDTO
     */
    country?: string;
    /**
     * 
     * @type {string}
     * @memberof AddressDTO
     */
    postalCode?: string;
    /**
     * 
     * @type {boolean}
     * @memberof AddressDTO
     */
    isPrimary?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AddressDTO
     */
    confirmed?: boolean;
    /**
     * 
     * @type {string}
     * @memberof AddressDTO
     */
    latitude?: string;
    /**
     * 
     * @type {string}
     * @memberof AddressDTO
     */
    longitude: string;
}
/**
 * 
 * @export
 * @interface AppDTO
 */
export interface AppDTO {
    /**
     * 
     * @type {string}
     * @memberof AppDTO
     */
    guid?: string;
    /**
     * 
     * @type {string}
     * @memberof AppDTO
     */
    appID?: string;
    /**
     * 
     * @type {string}
     * @memberof AppDTO
     */
    deviceID?: string;
    /**
     * 
     * @type {string}
     * @memberof AppDTO
     */
    pushToken?: string;
    /**
     * 
     * @type {string}
     * @memberof AppDTO
     */
    version?: string;
}
/**
 * 
 * @export
 * @interface Battery
 */
export interface Battery {
    /**
     * 
     * @type {boolean}
     * @memberof Battery
     */
    is_charging?: boolean;
    /**
     * 
     * @type {number}
     * @memberof Battery
     */
    level?: number;
}
/**
 * 
 * @export
 * @interface CaseBookFilterDTO
 */
export interface CaseBookFilterDTO {
    /**
     * 
     * @type {Array<string>}
     * @memberof CaseBookFilterDTO
     */
    province?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof CaseBookFilterDTO
     */
    caseTypes?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof CaseBookFilterDTO
     */
    cities?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof CaseBookFilterDTO
     */
    dateRange?: string;
    /**
     * 
     * @type {number}
     * @memberof CaseBookFilterDTO
     */
    kmRadius?: number;
    /**
     * 
     * @type {string}
     * @memberof CaseBookFilterDTO
     */
    latitude?: string;
    /**
     * 
     * @type {string}
     * @memberof CaseBookFilterDTO
     */
    longitude?: string;
}
/**
 * 
 * @export
 * @interface CaseBookResponse
 */
export interface CaseBookResponse {
    /**
     * 
     * @type {Array<CasebookCaseDTO>}
     * @memberof CaseBookResponse
     */
    caseBookPoints: Array<CasebookCaseDTO>;
    /**
     * 
     * @type {number}
     * @memberof CaseBookResponse
     */
    centerLatitude: number;
    /**
     * 
     * @type {number}
     * @memberof CaseBookResponse
     */
    centerLongitude: number;
    /**
     * 
     * @type {number}
     * @memberof CaseBookResponse
     */
    radius: number;
}
/**
 * 
 * @export
 * @interface CaseDTO
 */
export interface CaseDTO {
    /**
     * 
     * @type {number}
     * @memberof CaseDTO
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof CaseDTO
     */
    ownerId?: string;
    /**
     * 
     * @type {string}
     * @memberof CaseDTO
     */
    userName?: string;
    /**
     * 
     * @type {string}
     * @memberof CaseDTO
     */
    contactNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof CaseDTO
     */
    officerId?: string;
    /**
     * 
     * @type {string}
     * @memberof CaseDTO
     */
    caseOfficer?: string;
    /**
     * 
     * @type {string}
     * @memberof CaseDTO
     */
    title?: string;
    /**
     * 
     * @type {string}
     * @memberof CaseDTO
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof CaseDTO
     */
    reported?: string;
    /**
     * 
     * @type {string}
     * @memberof CaseDTO
     */
    opened?: string;
    /**
     * 
     * @type {string}
     * @memberof CaseDTO
     */
    closed?: string;
    /**
     * 
     * @type {string}
     * @memberof CaseDTO
     */
    reminder?: string;
    /**
     * 
     * @type {string}
     * @memberof CaseDTO
     */
    caseNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof CaseDTO
     */
    caseType?: string;
    /**
     * 
     * @type {string}
     * @memberof CaseDTO
     */
    caseLogo?: string;
    /**
     * 
     * @type {string}
     * @memberof CaseDTO
     */
    complaint?: string;
    /**
     * 
     * @type {CaseStatus}
     * @memberof CaseDTO
     */
    status?: CaseStatus;
    /**
     * 
     * @type {string}
     * @memberof CaseDTO
     */
    latitude?: string;
    /**
     * 
     * @type {string}
     * @memberof CaseDTO
     */
    longitude?: string;
    /**
     * 
     * @type {string}
     * @memberof CaseDTO
     */
    address?: string;
    /**
     * 
     * @type {string}
     * @memberof CaseDTO
     */
    sapsCaseNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof CaseDTO
     */
    sapsStationIssuingCase?: string;
    /**
     * 
     * @type {string}
     * @memberof CaseDTO
     */
    originalBranch?: string;
    /**
     * 
     * @type {number}
     * @memberof CaseDTO
     */
    originalBranchId?: number;
    /**
     * 
     * @type {string}
     * @memberof CaseDTO
     */
    currentBranch?: string;
    /**
     * 
     * @type {number}
     * @memberof CaseDTO
     */
    currentBranchId?: number;
}
/**
 * 
 * @export
 * @interface CaseFilterDTO
 */
export interface CaseFilterDTO {
    /**
     * 
     * @type {Array<string>}
     * @memberof CaseFilterDTO
     */
    caseStates?: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof CaseFilterDTO
     */
    pageNumber?: number;
    /**
     * 
     * @type {number}
     * @memberof CaseFilterDTO
     */
    pageSize?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof CaseFilterDTO
     */
    caseTypes?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof CaseFilterDTO
     */
    startDate?: string;
    /**
     * 
     * @type {string}
     * @memberof CaseFilterDTO
     */
    endDate?: string;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum CaseStatus {
    New = 'NEW',
    Open = 'OPEN',
    Onhold = 'ONHOLD',
    Pending = 'PENDING',
    Closed = 'CLOSED',
    Reminder = 'REMINDER'
}

/**
 * 
 * @export
 * @interface CasebookCaseDTO
 */
export interface CasebookCaseDTO {
    /**
     * 
     * @type {number}
     * @memberof CasebookCaseDTO
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof CasebookCaseDTO
     */
    title?: string;
    /**
     * 
     * @type {string}
     * @memberof CasebookCaseDTO
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof CasebookCaseDTO
     */
    caseType?: string;
    /**
     * 
     * @type {string}
     * @memberof CasebookCaseDTO
     */
    caseLogo?: string;
    /**
     * 
     * @type {CaseStatus}
     * @memberof CasebookCaseDTO
     */
    status?: CaseStatus;
    /**
     * 
     * @type {string}
     * @memberof CasebookCaseDTO
     */
    latitude?: string;
    /**
     * 
     * @type {string}
     * @memberof CasebookCaseDTO
     */
    longitude?: string;
    /**
     * 
     * @type {string}
     * @memberof CasebookCaseDTO
     */
    address?: string;
    /**
     * 
     * @type {boolean}
     * @memberof CasebookCaseDTO
     */
    track?: boolean;
}
/**
 * 
 * @export
 * @interface CityDTO
 */
export interface CityDTO {
    /**
     * 
     * @type {string}
     * @memberof CityDTO
     */
    provinceName: string;
    /**
     * 
     * @type {string}
     * @memberof CityDTO
     */
    provinceShort: string;
    /**
     * 
     * @type {string}
     * @memberof CityDTO
     */
    cityName: string;
}
/**
 * 
 * @export
 * @interface CompanyDTO
 */
export interface CompanyDTO {
    /**
     * 
     * @type {number}
     * @memberof CompanyDTO
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof CompanyDTO
     */
    name: string;
}
/**
 * 
 * @export
 * @interface ContactDTO
 */
export interface ContactDTO {
    /**
     * 
     * @type {number}
     * @memberof ContactDTO
     */
    id?: number;
    /**
     * 
     * @type {Array<ContactType>}
     * @memberof ContactDTO
     */
    contactType: Array<ContactType>;
    /**
     * 
     * @type {string}
     * @memberof ContactDTO
     */
    contactTypeOther?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ContactDTO
     */
    isMember: boolean;
    /**
     * 
     * @type {string}
     * @memberof ContactDTO
     */
    selfGuid?: string;
    /**
     * 
     * @type {string}
     * @memberof ContactDTO
     */
    targetGuid?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ContactDTO
     */
    emailConfirmed?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ContactDTO
     */
    phoneConfirmed?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ContactDTO
     */
    phonePrimary?: string;
    /**
     * 
     * @type {string}
     * @memberof ContactDTO
     */
    phoneWork?: string;
    /**
     * 
     * @type {string}
     * @memberof ContactDTO
     */
    phoneAlternate?: string;
    /**
     * 
     * @type {string}
     * @memberof ContactDTO
     */
    emailMain?: string;
    /**
     * 
     * @type {string}
     * @memberof ContactDTO
     */
    emailWork?: string;
    /**
     * 
     * @type {string}
     * @memberof ContactDTO
     */
    firstNames?: string;
    /**
     * 
     * @type {string}
     * @memberof ContactDTO
     */
    lastName?: string;
    /**
     * 
     * @type {string}
     * @memberof ContactDTO
     */
    alias?: string;
    /**
     * 
     * @type {string}
     * @memberof ContactDTO
     */
    dateOfBirth?: string;
    /**
     * 
     * @type {Array<AddressDTO>}
     * @memberof ContactDTO
     */
    addresses?: Array<AddressDTO>;
}
/**
 * 
 * @export
 * @interface ContactRelationship
 */
export interface ContactRelationship {
    /**
     * 
     * @type {Array<ContactType>}
     * @memberof ContactRelationship
     */
    contactType: Array<ContactType>;
    /**
     * 
     * @type {number}
     * @memberof ContactRelationship
     */
    contactId: number;
    /**
     * 
     * @type {string}
     * @memberof ContactRelationship
     */
    profileGuid: string;
    /**
     * 
     * @type {string}
     * @memberof ContactRelationship
     */
    ownerGuid?: string;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum ContactType {
    Self = 'SELF',
    Spouse = 'SPOUSE',
    Emergency1 = 'EMERGENCY1',
    Emergency2 = 'EMERGENCY2',
    Nextofkin = 'NEXTOFKIN',
    Dependent = 'DEPENDENT',
    MedicalAidDependent = 'MEDICAL_AID_DEPENDENT',
    Father = 'FATHER',
    Mother = 'MOTHER',
    Child = 'CHILD',
    Other = 'OTHER'
}

/**
 * 
 * @export
 * @interface Coords
 */
export interface Coords {
    /**
     * 
     * @type {number}
     * @memberof Coords
     */
    latitude?: number;
    /**
     * 
     * @type {number}
     * @memberof Coords
     */
    longitude?: number;
    /**
     * 
     * @type {number}
     * @memberof Coords
     */
    accuracy?: number;
    /**
     * 
     * @type {number}
     * @memberof Coords
     */
    speed?: number;
    /**
     * 
     * @type {number}
     * @memberof Coords
     */
    speed_accuracy?: number;
    /**
     * 
     * @type {number}
     * @memberof Coords
     */
    heading?: number;
    /**
     * 
     * @type {number}
     * @memberof Coords
     */
    heading_accuracy?: number;
    /**
     * 
     * @type {number}
     * @memberof Coords
     */
    altitude?: number;
    /**
     * 
     * @type {number}
     * @memberof Coords
     */
    altitude_accuracy?: number;
}
/**
 * 
 * @export
 * @interface DeviceDTO
 */
export interface DeviceDTO {
    /**
     * 
     * @type {string}
     * @memberof DeviceDTO
     */
    guid?: string;
    /**
     * 
     * @type {string}
     * @memberof DeviceDTO
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof DeviceDTO
     */
    phoneNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof DeviceDTO
     */
    imei?: string;
    /**
     * 
     * @type {string}
     * @memberof DeviceDTO
     */
    make?: string;
    /**
     * 
     * @type {string}
     * @memberof DeviceDTO
     */
    model?: string;
    /**
     * 
     * @type {OS}
     * @memberof DeviceDTO
     */
    osType?: OS;
    /**
     * 
     * @type {Array<AppDTO>}
     * @memberof DeviceDTO
     */
    apps?: Array<AppDTO>;
}
/**
 * 
 * @export
 * @interface ErpcDTO
 */
export interface ErpcDTO {
    /**
     * 
     * @type {number}
     * @memberof ErpcDTO
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof ErpcDTO
     */
    company: string;
    /**
     * 
     * @type {number}
     * @memberof ErpcDTO
     */
    companyId: number;
    /**
     * 
     * @type {number}
     * @memberof ErpcDTO
     */
    branchId: number;
    /**
     * 
     * @type {string}
     * @memberof ErpcDTO
     */
    branchName: string;
    /**
     * 
     * @type {string}
     * @memberof ErpcDTO
     */
    companyLogo?: string;
    /**
     * 
     * @type {string}
     * @memberof ErpcDTO
     */
    contactNo: string;
    /**
     * 
     * @type {string}
     * @memberof ErpcDTO
     */
    address: string;
}
/**
 * 
 * @export
 * @interface Extras
 */
export interface Extras {
    /**
     * 
     * @type {string}
     * @memberof Extras
     */
    event_type?: string;
}
/**
 * 
 * @export
 * @interface Location
 */
export interface Location {
    /**
     * 
     * @type {string}
     * @memberof Location
     */
    event?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Location
     */
    is_moving?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Location
     */
    uuid?: string;
    /**
     * 
     * @type {string}
     * @memberof Location
     */
    timestamp?: string;
    /**
     * 
     * @type {number}
     * @memberof Location
     */
    odometer?: number;
    /**
     * 
     * @type {Coords}
     * @memberof Location
     */
    coords?: Coords;
    /**
     * 
     * @type {Activity}
     * @memberof Location
     */
    activity?: Activity;
    /**
     * 
     * @type {Battery}
     * @memberof Location
     */
    battery?: Battery;
    /**
     * 
     * @type {Extras}
     * @memberof Location
     */
    extras?: Extras;
}
/**
 * 
 * @export
 * @interface LocationDto
 */
export interface LocationDto {
    /**
     * 
     * @type {number}
     * @memberof LocationDto
     */
    latutide: number;
    /**
     * 
     * @type {number}
     * @memberof LocationDto
     */
    longitude: number;
    /**
     * 
     * @type {number}
     * @memberof LocationDto
     */
    caseId?: number;
    /**
     * 
     * @type {string}
     * @memberof LocationDto
     */
    ownerGuid?: string;
}
/**
 * 
 * @export
 * @interface LocationNotificationDTO
 */
export interface LocationNotificationDTO {
    /**
     * 
     * @type {Location}
     * @memberof LocationNotificationDTO
     */
    location?: Location;
    /**
     * 
     * @type {string}
     * @memberof LocationNotificationDTO
     */
    user_id?: string;
    /**
     * 
     * @type {string}
     * @memberof LocationNotificationDTO
     */
    app_id?: string;
    /**
     * 
     * @type {string}
     * @memberof LocationNotificationDTO
     */
    device_id?: string;
    /**
     * 
     * @type {number}
     * @memberof LocationNotificationDTO
     */
    case_id?: number;
}
/**
 * 
 * @export
 * @interface MedicAlertDTO
 */
export interface MedicAlertDTO {
    /**
     * 
     * @type {number}
     * @memberof MedicAlertDTO
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof MedicAlertDTO
     */
    title: string;
    /**
     * 
     * @type {string}
     * @memberof MedicAlertDTO
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof MedicAlertDTO
     */
    notes?: string;
}
/**
 * 
 * @export
 * @interface MedicalInfoDTO
 */
export interface MedicalInfoDTO {
    /**
     * 
     * @type {number}
     * @memberof MedicalInfoDTO
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof MedicalInfoDTO
     */
    bloodType?: string;
    /**
     * 
     * @type {string}
     * @memberof MedicalInfoDTO
     */
    height?: string;
    /**
     * 
     * @type {string}
     * @memberof MedicalInfoDTO
     */
    weight?: string;
    /**
     * 
     * @type {boolean}
     * @memberof MedicalInfoDTO
     */
    everHadHeartAttack?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MedicalInfoDTO
     */
    everHadStroke?: boolean;
    /**
     * 
     * @type {string}
     * @memberof MedicalInfoDTO
     */
    biologicalGender?: string;
    /**
     * 
     * @type {string}
     * @memberof MedicalInfoDTO
     */
    medicalAidProvider?: string;
    /**
     * 
     * @type {string}
     * @memberof MedicalInfoDTO
     */
    medicalAidNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof MedicalInfoDTO
     */
    medicalAidPlan?: string;
    /**
     * 
     * @type {string}
     * @memberof MedicalInfoDTO
     */
    dependentCount?: string;
    /**
     * 
     * @type {boolean}
     * @memberof MedicalInfoDTO
     */
    principalMember?: boolean;
    /**
     * 
     * @type {string}
     * @memberof MedicalInfoDTO
     */
    brokerName?: string;
    /**
     * 
     * @type {string}
     * @memberof MedicalInfoDTO
     */
    brokerPhone?: string;
    /**
     * 
     * @type {string}
     * @memberof MedicalInfoDTO
     */
    brokerAddress?: string;
    /**
     * 
     * @type {string}
     * @memberof MedicalInfoDTO
     */
    allergies?: string;
    /**
     * 
     * @type {string}
     * @memberof MedicalInfoDTO
     */
    medicalDevices?: string;
    /**
     * 
     * @type {boolean}
     * @memberof MedicalInfoDTO
     */
    organDonor?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MedicalInfoDTO
     */
    dnr?: boolean;
    /**
     * 
     * @type {Array<MedicAlertDTO>}
     * @memberof MedicalInfoDTO
     */
    medicalAlerts: Array<MedicAlertDTO>;
    /**
     * 
     * @type {boolean}
     * @memberof MedicalInfoDTO
     */
    allowBloodProducts?: boolean;
    /**
     * 
     * @type {ContactDTO}
     * @memberof MedicalInfoDTO
     */
    preferredMedicalPractitioner?: ContactDTO;
    /**
     * 
     * @type {ContactDTO}
     * @memberof MedicalInfoDTO
     */
    preferredHospital?: ContactDTO;
    /**
     * 
     * @type {string}
     * @memberof MedicalInfoDTO
     */
    medicalHistory?: string;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum OS {
    Ios = 'IOS',
    Android = 'ANDROID'
}

/**
 * 
 * @export
 * @interface OptionDTO
 */
export interface OptionDTO {
    /**
     * 
     * @type {string}
     * @memberof OptionDTO
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof OptionDTO
     */
    text: string;
}
/**
 * 
 * @export
 * @interface PanicDTO
 */
export interface PanicDTO {
    /**
     * 
     * @type {string}
     * @memberof PanicDTO
     */
    sourceIdentifier: string;
    /**
     * 
     * @type {string}
     * @memberof PanicDTO
     */
    profileGuid: string;
    /**
     * 
     * @type {string}
     * @memberof PanicDTO
     */
    panicType: string;
    /**
     * 
     * @type {string}
     * @memberof PanicDTO
     */
    latitude?: string;
    /**
     * 
     * @type {string}
     * @memberof PanicDTO
     */
    longitude?: string;
    /**
     * 
     * @type {string}
     * @memberof PanicDTO
     */
    radiusM?: string;
}
/**
 * 
 * @export
 * @interface ProductDTO
 */
export interface ProductDTO {
    /**
     * 
     * @type {number}
     * @memberof ProductDTO
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof ProductDTO
     */
    title: string;
    /**
     * 
     * @type {string}
     * @memberof ProductDTO
     */
    description: string;
    /**
     * 
     * @type {number}
     * @memberof ProductDTO
     */
    price: number;
    /**
     * 
     * @type {string}
     * @memberof ProductDTO
     */
    formattedprice: string;
    /**
     * 
     * @type {number}
     * @memberof ProductDTO
     */
    vat: number;
    /**
     * 
     * @type {string}
     * @memberof ProductDTO
     */
    image?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ProductDTO
     */
    isPaid?: boolean;
}
/**
 * 
 * @export
 * @interface ProfileDTO
 */
export interface ProfileDTO {
    /**
     * 
     * @type {string}
     * @memberof ProfileDTO
     */
    guid: string;
    /**
     * 
     * @type {string}
     * @memberof ProfileDTO
     */
    tbxPassportId: string;
    /**
     * 
     * @type {string}
     * @memberof ProfileDTO
     */
    connectMeCode: string;
    /**
     * 
     * @type {string}
     * @memberof ProfileDTO
     */
    created?: string;
    /**
     * 
     * @type {string}
     * @memberof ProfileDTO
     */
    lastUpdated?: string;
    /**
     * 
     * @type {string}
     * @memberof ProfileDTO
     */
    firstNames: string;
    /**
     * 
     * @type {string}
     * @memberof ProfileDTO
     */
    lastName: string;
    /**
     * 
     * @type {string}
     * @memberof ProfileDTO
     */
    genderIdentityId?: string;
    /**
     * 
     * @type {string}
     * @memberof ProfileDTO
     */
    mainLanguageId?: string;
    /**
     * 
     * @type {string}
     * @memberof ProfileDTO
     */
    dateOfBirth?: string;
    /**
     * 
     * @type {string}
     * @memberof ProfileDTO
     */
    idNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof ProfileDTO
     */
    passportNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof ProfileDTO
     */
    occupationalSector?: string;
    /**
     * 
     * @type {string}
     * @memberof ProfileDTO
     */
    maritalStatus?: string;
    /**
     * 
     * @type {number}
     * @memberof ProfileDTO
     */
    numberOfChildDependents?: number;
    /**
     * 
     * @type {number}
     * @memberof ProfileDTO
     */
    numberOfAdultDependents?: number;
    /**
     * 
     * @type {string}
     * @memberof ProfileDTO
     */
    bio?: string;
    /**
     * 
     * @type {string}
     * @memberof ProfileDTO
     */
    mainCell: string;
    /**
     * 
     * @type {string}
     * @memberof ProfileDTO
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof ProfileDTO
     */
    profilePhoto?: string;
    /**
     * 
     * @type {string}
     * @memberof ProfileDTO
     */
    distinctiveFeatures?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ProfileDTO
     */
    glasses?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ProfileDTO
     */
    contactLenses?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ProfileDTO
     */
    falseTeeth?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ProfileDTO
     */
    hearingAids?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ProfileDTO
     */
    tattoos?: string;
    /**
     * 
     * @type {string}
     * @memberof ProfileDTO
     */
    piercings?: string;
    /**
     * 
     * @type {string}
     * @memberof ProfileDTO
     */
    heightAndBuild?: string;
    /**
     * 
     * @type {string}
     * @memberof ProfileDTO
     */
    hairColour?: string;
    /**
     * 
     * @type {string}
     * @memberof ProfileDTO
     */
    eyeColour?: string;
    /**
     * 
     * @type {string}
     * @memberof ProfileDTO
     */
    race?: string;
    /**
     * 
     * @type {ErpcDTO}
     * @memberof ProfileDTO
     */
    company?: ErpcDTO;
    /**
     * 
     * @type {string}
     * @memberof ProfileDTO
     */
    telegramId?: string;
    /**
     * 
     * @type {number}
     * @memberof ProfileDTO
     */
    userStatus?: number;
    /**
     * 
     * @type {Array<ContactDTO>}
     * @memberof ProfileDTO
     */
    contacts?: Array<ContactDTO>;
    /**
     * 
     * @type {MedicalInfoDTO}
     * @memberof ProfileDTO
     */
    medicalInfo?: MedicalInfoDTO;
}
/**
 * 
 * @export
 * @interface UpdateBranchDTO
 */
export interface UpdateBranchDTO {
    /**
     * 
     * @type {string}
     * @memberof UpdateBranchDTO
     */
    uid: string;
    /**
     * 
     * @type {number}
     * @memberof UpdateBranchDTO
     */
    branchId: number;
}
/**
 * 
 * @export
 * @interface UserDTO
 */
export interface UserDTO {
    /**
     * 
     * @type {string}
     * @memberof UserDTO
     */
    guid?: string;
    /**
     * 
     * @type {string}
     * @memberof UserDTO
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof UserDTO
     */
    cell?: string;
    /**
     * 
     * @type {string}
     * @memberof UserDTO
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof UserDTO
     */
    firstNames?: string;
    /**
     * 
     * @type {string}
     * @memberof UserDTO
     */
    lastName?: string;
    /**
     * 
     * @type {string}
     * @memberof UserDTO
     */
    base64Permissions?: string;
}

/**
 * AdminApi - axios parameter creator
 * @export
 */
export const AdminApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {AddressDTO} address 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminAddAddressToGuid: async (address: AddressDTO, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'address' is not null or undefined
            assertParamExists('adminAddAddressToGuid', 'address', address)
            const localVarPath = `/Admin/AddAddressToGuid`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(address, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ContactDTO} contact 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminAddContact: async (contact: ContactDTO, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'contact' is not null or undefined
            assertParamExists('adminAddContact', 'contact', contact)
            const localVarPath = `/Admin/AddContact`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(contact, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ContactRelationship} contact 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminAddContactRelation: async (contact: ContactRelationship, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'contact' is not null or undefined
            assertParamExists('adminAddContactRelation', 'contact', contact)
            const localVarPath = `/Admin/AddContactRelationToGuid`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(contact, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} companyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetBranches: async (companyId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('adminGetBranches', 'companyId', companyId)
            const localVarPath = `/Admin/GetBranches/{companyId}`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetCompanies: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/Admin/GetCompanies`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} guid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetContacts: async (guid: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'guid' is not null or undefined
            assertParamExists('adminGetContacts', 'guid', guid)
            const localVarPath = `/Admin/GetContacts/{guid}`
                .replace(`{${"guid"}}`, encodeURIComponent(String(guid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} guid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetDevicesForGuid: async (guid: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'guid' is not null or undefined
            assertParamExists('adminGetDevicesForGuid', 'guid', guid)
            const localVarPath = `/Admin/GetDevicesForGuid/{guid}`
                .replace(`{${"guid"}}`, encodeURIComponent(String(guid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} guid 
         * @param {string} key 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetFileLinkByKey: async (guid: string, key: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'guid' is not null or undefined
            assertParamExists('adminGetFileLinkByKey', 'guid', guid)
            // verify required parameter 'key' is not null or undefined
            assertParamExists('adminGetFileLinkByKey', 'key', key)
            const localVarPath = `/Admin/GetFileByKey/{guid}/{key}`
                .replace(`{${"guid"}}`, encodeURIComponent(String(guid)))
                .replace(`{${"key"}}`, encodeURIComponent(String(key)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} guid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetProfile: async (guid: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'guid' is not null or undefined
            assertParamExists('adminGetProfile', 'guid', guid)
            const localVarPath = `/Admin/MyProfile/{guid}`
                .replace(`{${"guid"}}`, encodeURIComponent(String(guid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} guid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminProductsForGuid: async (guid: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'guid' is not null or undefined
            assertParamExists('adminProductsForGuid', 'guid', guid)
            const localVarPath = `/Admin/ProductsForGuid/{guid}`
                .replace(`{${"guid"}}`, encodeURIComponent(String(guid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ContactDTO} contact 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminUpdateContact: async (contact: ContactDTO, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'contact' is not null or undefined
            assertParamExists('adminUpdateContact', 'contact', contact)
            const localVarPath = `/Admin/UpdateContact`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(contact, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UpdateBranchDTO} newBranch 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminUpdateERPCForUID: async (newBranch: UpdateBranchDTO, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'newBranch' is not null or undefined
            assertParamExists('adminUpdateERPCForUID', 'newBranch', newBranch)
            const localVarPath = `/Admin/UpdateERPCForUID`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(newBranch, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ProfileDTO} profile 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminUpdateProfile: async (profile: ProfileDTO, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'profile' is not null or undefined
            assertParamExists('adminUpdateProfile', 'profile', profile)
            const localVarPath = `/Admin/UpdateProfile`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(profile, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AdminApi - functional programming interface
 * @export
 */
export const AdminApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AdminApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {AddressDTO} address 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminAddAddressToGuid(address: AddressDTO, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProfileDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminAddAddressToGuid(address, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {ContactDTO} contact 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminAddContact(contact: ContactDTO, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminAddContact(contact, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {ContactRelationship} contact 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminAddContactRelation(contact: ContactRelationship, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ContactDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminAddContactRelation(contact, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} companyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminGetBranches(companyId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ErpcDTO>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminGetBranches(companyId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminGetCompanies(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CompanyDTO>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminGetCompanies(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} guid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminGetContacts(guid: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ContactDTO>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminGetContacts(guid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} guid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminGetDevicesForGuid(guid: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<DeviceDTO>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminGetDevicesForGuid(guid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} guid 
         * @param {string} key 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminGetFileLinkByKey(guid: string, key: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminGetFileLinkByKey(guid, key, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} guid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminGetProfile(guid: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProfileDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminGetProfile(guid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} guid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminProductsForGuid(guid: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ProductDTO>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminProductsForGuid(guid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {ContactDTO} contact 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminUpdateContact(contact: ContactDTO, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ContactDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminUpdateContact(contact, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {UpdateBranchDTO} newBranch 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminUpdateERPCForUID(newBranch: UpdateBranchDTO, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminUpdateERPCForUID(newBranch, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {ProfileDTO} profile 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminUpdateProfile(profile: ProfileDTO, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProfileDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminUpdateProfile(profile, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AdminApi - factory interface
 * @export
 */
export const AdminApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AdminApiFp(configuration)
    return {
        /**
         * 
         * @param {AddressDTO} address 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminAddAddressToGuid(address: AddressDTO, options?: any): AxiosPromise<ProfileDTO> {
            return localVarFp.adminAddAddressToGuid(address, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ContactDTO} contact 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminAddContact(contact: ContactDTO, options?: any): AxiosPromise<string> {
            return localVarFp.adminAddContact(contact, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ContactRelationship} contact 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminAddContactRelation(contact: ContactRelationship, options?: any): AxiosPromise<ContactDTO> {
            return localVarFp.adminAddContactRelation(contact, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} companyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetBranches(companyId: number, options?: any): AxiosPromise<Array<ErpcDTO>> {
            return localVarFp.adminGetBranches(companyId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetCompanies(options?: any): AxiosPromise<Array<CompanyDTO>> {
            return localVarFp.adminGetCompanies(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} guid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetContacts(guid: string, options?: any): AxiosPromise<Array<ContactDTO>> {
            return localVarFp.adminGetContacts(guid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} guid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetDevicesForGuid(guid: string, options?: any): AxiosPromise<Array<DeviceDTO>> {
            return localVarFp.adminGetDevicesForGuid(guid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} guid 
         * @param {string} key 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetFileLinkByKey(guid: string, key: string, options?: any): AxiosPromise<any> {
            return localVarFp.adminGetFileLinkByKey(guid, key, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} guid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetProfile(guid: string, options?: any): AxiosPromise<ProfileDTO> {
            return localVarFp.adminGetProfile(guid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} guid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminProductsForGuid(guid: string, options?: any): AxiosPromise<Array<ProductDTO>> {
            return localVarFp.adminProductsForGuid(guid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ContactDTO} contact 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminUpdateContact(contact: ContactDTO, options?: any): AxiosPromise<ContactDTO> {
            return localVarFp.adminUpdateContact(contact, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UpdateBranchDTO} newBranch 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminUpdateERPCForUID(newBranch: UpdateBranchDTO, options?: any): AxiosPromise<boolean> {
            return localVarFp.adminUpdateERPCForUID(newBranch, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ProfileDTO} profile 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminUpdateProfile(profile: ProfileDTO, options?: any): AxiosPromise<ProfileDTO> {
            return localVarFp.adminUpdateProfile(profile, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AdminApi - object-oriented interface
 * @export
 * @class AdminApi
 * @extends {BaseAPI}
 */
export class AdminApi extends BaseAPI {
    /**
     * 
     * @param {AddressDTO} address 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminAddAddressToGuid(address: AddressDTO, options?: any) {
        return AdminApiFp(this.configuration).adminAddAddressToGuid(address, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ContactDTO} contact 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminAddContact(contact: ContactDTO, options?: any) {
        return AdminApiFp(this.configuration).adminAddContact(contact, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ContactRelationship} contact 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminAddContactRelation(contact: ContactRelationship, options?: any) {
        return AdminApiFp(this.configuration).adminAddContactRelation(contact, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} companyId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminGetBranches(companyId: number, options?: any) {
        return AdminApiFp(this.configuration).adminGetBranches(companyId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminGetCompanies(options?: any) {
        return AdminApiFp(this.configuration).adminGetCompanies(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} guid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminGetContacts(guid: string, options?: any) {
        return AdminApiFp(this.configuration).adminGetContacts(guid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} guid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminGetDevicesForGuid(guid: string, options?: any) {
        return AdminApiFp(this.configuration).adminGetDevicesForGuid(guid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} guid 
     * @param {string} key 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminGetFileLinkByKey(guid: string, key: string, options?: any) {
        return AdminApiFp(this.configuration).adminGetFileLinkByKey(guid, key, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} guid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminGetProfile(guid: string, options?: any) {
        return AdminApiFp(this.configuration).adminGetProfile(guid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} guid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminProductsForGuid(guid: string, options?: any) {
        return AdminApiFp(this.configuration).adminProductsForGuid(guid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ContactDTO} contact 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminUpdateContact(contact: ContactDTO, options?: any) {
        return AdminApiFp(this.configuration).adminUpdateContact(contact, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UpdateBranchDTO} newBranch 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminUpdateERPCForUID(newBranch: UpdateBranchDTO, options?: any) {
        return AdminApiFp(this.configuration).adminUpdateERPCForUID(newBranch, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ProfileDTO} profile 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminUpdateProfile(profile: ProfileDTO, options?: any) {
        return AdminApiFp(this.configuration).adminUpdateProfile(profile, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * CaseApi - axios parameter creator
 * @export
 */
export const CaseApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {CaseDTO} caseDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        caseCreateCase: async (caseDto: CaseDTO, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'caseDto' is not null or undefined
            assertParamExists('caseCreateCase', 'caseDto', caseDto)
            const localVarPath = `/Case/CreateCase`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(caseDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CaseBookFilterDTO} filter 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        caseGetCaseBookCases: async (filter: CaseBookFilterDTO, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'filter' is not null or undefined
            assertParamExists('caseGetCaseBookCases', 'filter', filter)
            const localVarPath = `/Case/GetCaseBookCases`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(filter, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        caseGetCaseById: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('caseGetCaseById', 'id', id)
            const localVarPath = `/Case/GetCaseById/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        caseGetCaseCategoryOptions: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/Case/GetCaseCategoryOptions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        caseGetCases: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/Case/GetCases`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        caseGetCities: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/Case/GetCities`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        caseGetConnectionString: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/Case/GetConnectionString`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CaseFilterDTO} caseFilterDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        caseGetFilteredCases: async (caseFilterDto: CaseFilterDTO, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'caseFilterDto' is not null or undefined
            assertParamExists('caseGetFilteredCases', 'caseFilterDto', caseFilterDto)
            const localVarPath = `/Case/GetFilteredCases`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(caseFilterDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} caseId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        caseGetLocationsForCase: async (caseId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'caseId' is not null or undefined
            assertParamExists('caseGetLocationsForCase', 'caseId', caseId)
            const localVarPath = `/Case/GetLocationsForCase`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(caseId, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {LocationNotificationDTO} location 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        caseLocationUpdate: async (location: LocationNotificationDTO, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'location' is not null or undefined
            assertParamExists('caseLocationUpdate', 'location', location)
            const localVarPath = `/Case/LocationUpdate`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(location, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {PanicDTO} panicDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        casePanic: async (panicDto: PanicDTO, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'panicDto' is not null or undefined
            assertParamExists('casePanic', 'panicDto', panicDto)
            const localVarPath = `/Case/Panic`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(panicDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} partial 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        caseSearchPredict: async (partial: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'partial' is not null or undefined
            assertParamExists('caseSearchPredict', 'partial', partial)
            const localVarPath = `/Case/SearchPredict/{partial}`
                .replace(`{${"partial"}}`, encodeURIComponent(String(partial)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        caseTestBus: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/Case/TestBus`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CaseDTO} caseDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        caseUpdateCase: async (caseDto: CaseDTO, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'caseDto' is not null or undefined
            assertParamExists('caseUpdateCase', 'caseDto', caseDto)
            const localVarPath = `/Case/UpdateCase`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(caseDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CaseApi - functional programming interface
 * @export
 */
export const CaseApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CaseApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {CaseDTO} caseDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async caseCreateCase(caseDto: CaseDTO, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CaseDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.caseCreateCase(caseDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {CaseBookFilterDTO} filter 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async caseGetCaseBookCases(filter: CaseBookFilterDTO, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CaseBookResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.caseGetCaseBookCases(filter, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async caseGetCaseById(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CaseDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.caseGetCaseById(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async caseGetCaseCategoryOptions(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<OptionDTO>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.caseGetCaseCategoryOptions(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async caseGetCases(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CaseDTO>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.caseGetCases(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async caseGetCities(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CityDTO>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.caseGetCities(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async caseGetConnectionString(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.caseGetConnectionString(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {CaseFilterDTO} caseFilterDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async caseGetFilteredCases(caseFilterDto: CaseFilterDTO, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CaseDTO>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.caseGetFilteredCases(caseFilterDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} caseId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async caseGetLocationsForCase(caseId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<LocationDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.caseGetLocationsForCase(caseId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {LocationNotificationDTO} location 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async caseLocationUpdate(location: LocationNotificationDTO, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.caseLocationUpdate(location, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {PanicDTO} panicDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async casePanic(panicDto: PanicDTO, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CaseDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.casePanic(panicDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} partial 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async caseSearchPredict(partial: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<UserDTO>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.caseSearchPredict(partial, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async caseTestBus(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.caseTestBus(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {CaseDTO} caseDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async caseUpdateCase(caseDto: CaseDTO, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CaseDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.caseUpdateCase(caseDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CaseApi - factory interface
 * @export
 */
export const CaseApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CaseApiFp(configuration)
    return {
        /**
         * 
         * @param {CaseDTO} caseDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        caseCreateCase(caseDto: CaseDTO, options?: any): AxiosPromise<CaseDTO> {
            return localVarFp.caseCreateCase(caseDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CaseBookFilterDTO} filter 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        caseGetCaseBookCases(filter: CaseBookFilterDTO, options?: any): AxiosPromise<CaseBookResponse> {
            return localVarFp.caseGetCaseBookCases(filter, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        caseGetCaseById(id: number, options?: any): AxiosPromise<CaseDTO> {
            return localVarFp.caseGetCaseById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        caseGetCaseCategoryOptions(options?: any): AxiosPromise<Array<OptionDTO>> {
            return localVarFp.caseGetCaseCategoryOptions(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        caseGetCases(options?: any): AxiosPromise<Array<CaseDTO>> {
            return localVarFp.caseGetCases(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        caseGetCities(options?: any): AxiosPromise<Array<CityDTO>> {
            return localVarFp.caseGetCities(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        caseGetConnectionString(options?: any): AxiosPromise<string> {
            return localVarFp.caseGetConnectionString(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CaseFilterDTO} caseFilterDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        caseGetFilteredCases(caseFilterDto: CaseFilterDTO, options?: any): AxiosPromise<Array<CaseDTO>> {
            return localVarFp.caseGetFilteredCases(caseFilterDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} caseId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        caseGetLocationsForCase(caseId: number, options?: any): AxiosPromise<Array<LocationDto>> {
            return localVarFp.caseGetLocationsForCase(caseId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {LocationNotificationDTO} location 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        caseLocationUpdate(location: LocationNotificationDTO, options?: any): AxiosPromise<string> {
            return localVarFp.caseLocationUpdate(location, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {PanicDTO} panicDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        casePanic(panicDto: PanicDTO, options?: any): AxiosPromise<CaseDTO> {
            return localVarFp.casePanic(panicDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} partial 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        caseSearchPredict(partial: string, options?: any): AxiosPromise<Array<UserDTO>> {
            return localVarFp.caseSearchPredict(partial, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        caseTestBus(options?: any): AxiosPromise<string> {
            return localVarFp.caseTestBus(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CaseDTO} caseDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        caseUpdateCase(caseDto: CaseDTO, options?: any): AxiosPromise<CaseDTO> {
            return localVarFp.caseUpdateCase(caseDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CaseApi - object-oriented interface
 * @export
 * @class CaseApi
 * @extends {BaseAPI}
 */
export class CaseApi extends BaseAPI {
    /**
     * 
     * @param {CaseDTO} caseDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CaseApi
     */
    public caseCreateCase(caseDto: CaseDTO, options?: any) {
        return CaseApiFp(this.configuration).caseCreateCase(caseDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CaseBookFilterDTO} filter 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CaseApi
     */
    public caseGetCaseBookCases(filter: CaseBookFilterDTO, options?: any) {
        return CaseApiFp(this.configuration).caseGetCaseBookCases(filter, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CaseApi
     */
    public caseGetCaseById(id: number, options?: any) {
        return CaseApiFp(this.configuration).caseGetCaseById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CaseApi
     */
    public caseGetCaseCategoryOptions(options?: any) {
        return CaseApiFp(this.configuration).caseGetCaseCategoryOptions(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CaseApi
     */
    public caseGetCases(options?: any) {
        return CaseApiFp(this.configuration).caseGetCases(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CaseApi
     */
    public caseGetCities(options?: any) {
        return CaseApiFp(this.configuration).caseGetCities(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CaseApi
     */
    public caseGetConnectionString(options?: any) {
        return CaseApiFp(this.configuration).caseGetConnectionString(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CaseFilterDTO} caseFilterDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CaseApi
     */
    public caseGetFilteredCases(caseFilterDto: CaseFilterDTO, options?: any) {
        return CaseApiFp(this.configuration).caseGetFilteredCases(caseFilterDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} caseId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CaseApi
     */
    public caseGetLocationsForCase(caseId: number, options?: any) {
        return CaseApiFp(this.configuration).caseGetLocationsForCase(caseId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {LocationNotificationDTO} location 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CaseApi
     */
    public caseLocationUpdate(location: LocationNotificationDTO, options?: any) {
        return CaseApiFp(this.configuration).caseLocationUpdate(location, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {PanicDTO} panicDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CaseApi
     */
    public casePanic(panicDto: PanicDTO, options?: any) {
        return CaseApiFp(this.configuration).casePanic(panicDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} partial 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CaseApi
     */
    public caseSearchPredict(partial: string, options?: any) {
        return CaseApiFp(this.configuration).caseSearchPredict(partial, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CaseApi
     */
    public caseTestBus(options?: any) {
        return CaseApiFp(this.configuration).caseTestBus(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CaseDTO} caseDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CaseApi
     */
    public caseUpdateCase(caseDto: CaseDTO, options?: any) {
        return CaseApiFp(this.configuration).caseUpdateCase(caseDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * TestApi - axios parameter creator
 * @export
 */
export const TestApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testHealthCheck: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/Test`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TestApi - functional programming interface
 * @export
 */
export const TestApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TestApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async testHealthCheck(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.testHealthCheck(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * TestApi - factory interface
 * @export
 */
export const TestApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TestApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testHealthCheck(options?: any): AxiosPromise<any> {
            return localVarFp.testHealthCheck(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * TestApi - object-oriented interface
 * @export
 * @class TestApi
 * @extends {BaseAPI}
 */
export class TestApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TestApi
     */
    public testHealthCheck(options?: any) {
        return TestApiFp(this.configuration).testHealthCheck(options).then((request) => request(this.axios, this.basePath));
    }
}


