












































import {vxm} from "@/store";
import {Component, Vue, Prop} from 'vue-property-decorator';
import {AppDTO, KeyValue} from "@shared_vue/openapi/myvaultapi";

@Component
export default class AppCard extends Vue {
  //note that Devices are a subclass of Assets So this is very similar to AssetCard.
  //I only made is separate to potentially encapsulate different logic or mandatory fields
  @Prop(Number) readonly id: number | undefined;
  @Prop(String) readonly src: string | undefined;
  @Prop({default: 'true'}) readonly showButton : boolean | undefined;
  @Prop() readonly app: AppDTO | undefined;
  @Prop(String) readonly btnText: string | undefined;
  @Prop() readonly buttonFunction: Function | undefined;
  private ui = vxm.ui;
  private editMode = false;
  private expanded = false;
  //note these dynamic static assets will likely be served from S3

  private publicPath = process.env.VUE_APP_STATIC_DIR;
  private keyValues: KeyValue[] = []


  private expand(){
    if (this.buttonFunction) //rather use emit?
      this.buttonFunction(this.id);
    this.expanded = !this.expanded;
    // console.log(JSON.stringify(this.keyValues[0]))
  }
  private save(){
    this.expanded = !this.expanded;
  }

}
