import {action, createModule, mutation} from "vuex-class-component";
import {CartDTO, CartState, Configuration, LineItemDTO, ShopApiFactory} from "@shared_vue/openapi/myvaultapi";


const VuexModule = createModule({
    namespaced: "shop",
    strict: false,
})

export class ShopStore extends VuexModule {

    private version = '0.1';
    public cart: CartDTO = ShopStore.getEmptyCart();
    private shopApi = ShopApiFactory(<Configuration>{basePath:process.env.VUE_APP_MYVAULT_API_URL},process.env.VUE_APP_MYVAULT_API_URL);

    private static getEmptyCart(): CartDTO {
        return <CartDTO>{
            version: '0.1',
            updated: '2017-01-01',
            lines: [],
            state: CartState.Open
        }
    }
    
    @mutation setCart(cart: CartDTO){
        this.cart=cart;
    }

    @mutation setLine(lineItem: LineItemDTO){
        if (lineItem.product.id != null) {
            let foundItem = this.cart.lines.find(a=>a.product.id==lineItem.product.id);
            if (foundItem){
                let index = this.cart.lines.indexOf(foundItem);
                this.cart.lines[index]=lineItem;
            } else {
                this.cart.lines.push(lineItem);
            }
            this.cart.updated=new Date().toJSON();
        }
    }

    @mutation dropLine(key:number){
        let foundItem = this.cart.lines.find(a=>a.product.id==key);
        if (foundItem){
            let index = this.cart.lines.indexOf(foundItem);
            this.cart.lines.splice(index,1);
            this.cart.updated=new Date().toJSON();
        }
    }

    @mutation increment(key:number){
        let line = this.cart.lines.find(a=>a.product.id==key);
        if (line){
            line.quantity+=1;
            this.setLine(line);
            this.cart.updated=new Date().toJSON();
        }
    }

    @mutation decrement(key:number){
        let line = this.cart.lines.find(a=>a.product.id==key);
        if (line){ //should this logic exist in the action rather?
            line.quantity-=1;
            if (line.quantity!=0) {
                this.setLine(line);
            } else {
                this.dropLine(key);
            }
            this.cart.updated=new Date().toJSON();
        }
    }

    @mutation setState(state: CartState){
        this.cart.state=state;
        this.cart.updated=new Date().toJSON();
    }

    @action async replaceCart(cart: CartDTO){
        this.setCart(cart);
    }
    //check if this item already exists in the cart
    //any action needs to sync the cart with cookie/server?
    @action async addToCart(lineItem: LineItemDTO) {

        this.shopApi.shopAddToCart(lineItem)
            .then(
                resp=>this.replaceCart(resp.data))
            .catch(()=>console.log('failed!')); //handle failure and retries here and cart syncing
        // // this needs to async update the DB but return fast
        // let currentLine=undefined;
        // try {
        //     currentLine = this.cart.lines.find(a=>a.product.id==lineItem.product.id);
        // } catch (e) {
        //     this.cart.lines=[];
        // }
        // if (currentLine!=undefined) {
        //     let currentQuantity = currentLine.quantity;
        //     lineItem.quantity += currentQuantity;
        // }
        // this.setLine(lineItem);
    }

    //delete old line
    //todo: hooks into backend to delete/add/change quantity there too
    @action async deleteLine(line:LineItemDTO){
        this.shopApi.shopDeleteLine(line)
            .then(
                resp=>this.replaceCart(resp.data))
            .catch(()=>console.log('failed!'));
    }

    //overwrite line do not merge
    @action async alterQuantity(lineItem: LineItemDTO){
        this.shopApi.shopUpdateLineQuantity(lineItem)
            .then(
                resp=>this.replaceCart(resp.data))
            .catch(()=>console.log('failed!'));
    }

    @action async incrementLine(productId:number){

    }

    //check if you hit zero
    @action async decrementLine(productId:number){

    }

    get GetCart(){
        return this.cart;
    }

    public GetLineForProductId(productId: number){
        return this.cart.lines.find(a=>a.product.id==productId);
    }

    get GetCartArray() : LineItemDTO[] {
        return this.cart.lines;
    }

    get CartSize(){
        return this.cart.lines.length;
    }

    get ItemCount() {
        return this.cart.lines.map(item => item.quantity).reduce((prev, next) => prev + next);
    }

    get SubscriptionSum(){
        return this.cart.lines.map(item => item.product.price * item.quantity).reduce((prev, next) => prev + next);
    }

}
