























































import {vxm} from "@/store";
import {Component, Vue} from 'vue-property-decorator';
import ErpcDashCaseView from "@/views/viewcomponents/ErpcDashCaseView.vue";
import RequestView from "@/views/viewcomponents/RequestView.vue";
import SmileyRater from "@/views/widgets/SmileyRater.vue";
import WaitModal from "@/views/widgets/modals/WaitModal.vue";


import {Configuration, HelpDeskApiFactory, ProfileApiFactory, TicketDTO} from "@shared_vue/openapi/myvaultapi";

import {ServiceBusClient, ServiceBusReceiver} from "@azure/service-bus";

@Component({
  components: {SmileyRater, ErpcDashCaseView, RequestView, WaitModal}
})

export default class HelpDesk extends Vue {
  private ui = vxm.ui;
  private auth = vxm.auth;
  private showWait: boolean = false;
  private showNew: boolean = false;
  private newCat: any = 0;
  private helpdeskAPI = HelpDeskApiFactory(<Configuration>{basePath:process.env.VUE_APP_MYVAULT_API_URL},process.env.VUE_APP_MYVAULT_API_URL);
  private ticketList : TicketDTO[][] = []
  private newTitle : string = '';
  private newDescription : string = '';
  private currentTickets: TicketDTO[] = []
  private waitText: string = "Loading..."
  private catOptions = [
    {value:'Murder', label:'Murder'}
  ]
  // private connection: HubConnection | undefined;

  private serviceBusClient: ServiceBusClient | undefined;
  private receiver: ServiceBusReceiver|undefined;

  mounted() {
    //  this.connection = new signalR.HubConnectionBuilder()
    //     .withUrl("http://localhost:5000/tickethub")
    //      .withAutomaticReconnect()
    //     .build();
    //
    // this.connection.on("NotifyNewTicket", data => {
    //   console.log(data);
    //   this.fetchAll();
    // });

    // this.connection.start();
    this.configureSB();

    this.fetchAll();
  }

  private async fetchCase(id: number){
    console.log('fetching case for id: ' + id);
    let response = await this.helpdeskAPI.helpDeskGetHelpDeskRequestById(id)
    if (response.status == 200) {
      console.log(response.data)
      // need to deduplicate. If this id already exists just replace it

    }
  }
  private myMessageHandler = async (message: any) => { //type this?
    // your code here
    console.log('message received: ' + JSON.stringify(message));
    /*
    {
    "CaseId": "32",
    "Event": 0,
    "Source": "HelpDeskController",
    "Extra": null,
    "Meta": null,
    "OwnerGuid": "3g4vUVsBcMQ9ruD6GuZnNzAwIoj1",
    "OpGuid": "",
    "OriginalId": "3",
    "EventName": "New Ticket"
}
     */
    await this.fetchCase(message.CaseId);
  };
  private myErrorHandler = async (args: any) => {
    console.log(
        `Error occurred with ${args.entityPath} within ${args.fullyQualifiedNamespace}: `,
        args.error
    );
  };

  private async configureSB(){
    let connectionString = await this.helpdeskAPI.helpDeskGetConnectionString();
    console.log('sb connectionstring: ' + connectionString.data);
    this.serviceBusClient = new ServiceBusClient(connectionString.data);
    this.receiver = this.serviceBusClient.createReceiver("tickets", <string>this.auth.UserId);

    this.receiver.subscribe({
      processMessage: this.myMessageHandler,
      processError: this.myErrorHandler
    });
  }

  private showThread(id: number){
    this.currentTickets=this.ticketList.filter(s=>s[0].id==id)[0]; //eyyy
  }

  private sendReply(text: string){
    this.waitText="Saving...";
    this.showWait=true;
    let newTicketDTO = <TicketDTO>{};
    newTicketDTO.ownerId = this.auth.UserId;
    newTicketDTO.description = text;
    //copy title and original id from original ticket
    newTicketDTO.originalTicketId=this.currentTickets[0].id;
    newTicketDTO.title=this.currentTickets[0].title;

    // newTicketDTO.category=this.newCat.value; // should we use ID? idk
    this.helpdeskAPI.helpDeskNewHelpDeskRequest(newTicketDTO).then(result=>{
      console.log(result)
      if (result.status==200){
        console.log('you did it!')
      }
    }).catch(err=>console.log(err)).finally(()=>{this.dismiss();
      this.fetchAll();});
  }

  private fetchAll(){
    this.waitText="Loading..."
    this.showWait=true;
    this.helpdeskAPI.helpDeskGetGroupedHelpDeskTickets().then(resp => {
      console.log(resp.data);
      //add version stuff here :-O
      this.ticketList=resp.data;
      if (this.currentTickets&&this.currentTickets.length>0){
        this.showThread(this.currentTickets[0].id!)
      }
    })
        .catch(err => {
          // Handle Error Here
          console.error(err);
        }).finally(()=>{
      this.showWait=false;
    });
  }

  private submitNew(){
    console.log('saving...')
    this.waitText="Saving...";
    //add waiting modal
    let newTicketDTO = <TicketDTO>{};
    newTicketDTO.ownerId = this.auth.UserId;
    newTicketDTO.title=this.newTitle;
    newTicketDTO.description=this.newDescription;
    //category and rest
    // newTicketDTO.category=this.newCat.value; // should we use ID? idk
    this.helpdeskAPI.helpDeskNewHelpDeskRequest(newTicketDTO).then(result=>{
      console.log(result)
      if (result.status==200) console.log('yay!')
    }).catch(err=>console.log(err)).finally(()=>{this.dismiss();
      this.fetchAll();});
  }

  public dismiss(){
    this.showNew=false;
    this.showWait=false;
  }

  get CatName(){
    return this.catOptions.find(a=>a.value==this.newCat)?.label??''
  }
  private newCase1() {
    this.showNew = true;
  }

}
