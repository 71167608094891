





























































import {Component, Prop, PropSync, Vue} from 'vue-property-decorator';

import TextBubble from "@/views/widgets/TextBubble.vue";
import TextBubble2 from "@/views/widgets/TextBubble2.vue";
import SmileyRater from "@/views/widgets/SmileyRater.vue";
import {vxm} from "@/store";
import {TicketDTO} from "@shared_vue/openapi/myvaultapi";


@Component({
components: {TextBubble,TextBubble2,SmileyRater}
})

export default class RequestView extends Vue {
// @Prop(String) readonly description: string | undefined;
// @Prop({default: 'false'}) readonly active : boolean | undefined; //is this the currently selected one
// @Prop({default: 'false'}) readonly read : boolean | undefined; //has user read this one
// @Prop(String) readonly state : string | undefined; //e.g. open
// @Prop(String) readonly title : string | undefined;
// @Prop(String) readonly count : string | undefined; //how many in thread
// @Prop(String) readonly date : string | undefined; //create date
//   @Prop({default: []}) readonly tickets!: TicketDTO[];
  @PropSync('tickets', { default: [] }) syncedTickets!: TicketDTO[]

  private auth = vxm.auth;
  private replyText: string = '';

  private pointySide(owner: string){
    if (owner==this.auth.UserId) {
      return "btm-right-in";
    } else {
      return "btm-left-in";
    }
  }
  get mainTicket(){
    return this.syncedTickets[0];
  }

  private sendReply(){
    this.$emit('sendReply',this.replyText)
    this.replyText=''
  }
}
