<template>
  <div class="ribbon-content" :class="type">
    <div class="ribbon base">
      <div class="mb-0 value">{{value}}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ribbon",
  props: {
    type: {
      type: String,
      default: 'md'
    },
    value: {
      type: String,
      default: ''
    },
  }
}
</script>

<style scoped>
.ribbon {
  position: absolute;
  top: 20px;
  right: -5px;
  padding: 5px;
}

.ribbon-content {
  position: relative;
  min-height: 70px;
  margin-right: 15px;
}

.ribbon.base {
  background: #E4FAE2;
  color: #756E6E;
}

.ribbon:before, .ribbon:after {
  content: '';
  position: absolute;
  right: -9px;
  border-right: 10px solid transparent;
}

.ribbon:before {
  top: 0;
}

.ribbon:after {
  bottom: 0;
}


.value{
  font-size: 15px;
}

.ribbon.base:before {
  border-top: 27px solid #E4FAE2 !important;
}

.ribbon.base:after {
  border-bottom: 27px solid #E4FAE2 !important;
}
.lg .value{
  font-size: 1.6rem!important;
}
</style>
