






























































import {vxm} from "@/store";
import {Component, Vue} from 'vue-property-decorator';
import ShopProduct from "@/views/widgets/ShopProduct.vue";
import _ from "lodash";
import {Configuration, ProductDTO, ShopApiFactory} from "@shared_vue/openapi/myvaultapi";

@Component({
    components: {ShopProduct}
})

export default class ShopPage extends Vue {
    private ui = vxm.ui;
    private shop = vxm.shop;
    private shopApi = ShopApiFactory(<Configuration>{basePath:process.env.VUE_APP_MYVAULT_API_URL},process.env.VUE_APP_MYVAULT_API_URL);
    private productList: ProductDTO[] = [];
    private itemsPerRow = 6;
    private toasts: any[] = []

    get addProductToastConfig () {
      return {
        autohide: 2000,
        closeButton: true,
        fade: true,
        position: 'top-right',
        header: 'Success!'
      }
    }
    private addedProduct(){
      console.log('added product event')
      this.toasts.push(this.addProductToastConfig)
    }
    mounted() {
      this.shopApi.shopGetStoreProducts().then(resp => {
        console.log(resp.data);
        //add version stuff here :-O
        this.productList = resp.data;
        console.log(this.productList);
      })
          .catch(err => {
            // Handle Error Here
            console.error(err);
          }).finally(() => {

      });
    }

    get productChunks() {
        return _.chunk(Object.values(this.products), this.itemsPerRow);
    }

    private recommendedProducts = [
      <ProductDTO>{id:1, title:"Sample Product",description:"Some Description",price:100.00,formattedprice:"R 100.00",vat:0,image:'https://via.placeholder.com/150'},
      <ProductDTO>{id:2, title:"Sample Product",description:"Some Description",price:200.00,formattedprice:"R 200.00",vat:0,image:'https://via.placeholder.com/150'},
      <ProductDTO>{id:3, title:"Sample Product",description:"Some Description",price:300.00,formattedprice:"R 300.00",vat:0,image:'https://via.placeholder.com/150'},
    ]

    private yourAreaProducts  = [
      <ProductDTO>{id:1, title:"Sample Product",description:"Some Description",price:100.00,formattedprice:"R 100.00",vat:0,image:'https://via.placeholder.com/150'},
      <ProductDTO>{id:2, title:"Sample Product",description:"Some Description",price:200.00,formattedprice:"R 200.00",vat:0,image:'https://via.placeholder.com/150'},
      <ProductDTO>{id:3, title:"Sample Product",description:"Some Description",price:300.00,formattedprice:"R 300.00",vat:0,image:'https://via.placeholder.com/150'},
    ]

    private products = [
      <ProductDTO>{id:1,title:"AAA Response Premium",description:"Some Description",price:100.00,formattedprice:"R 100.00",vat:0,image:'https://via.placeholder.com/150'},
      <ProductDTO>{id:2,title:"Advanced CoDriver Assist",description:"Some Description",price:200.00,formattedprice:"R 200.00",vat:0,image:'https://via.placeholder.com/150'},
      <ProductDTO>{id:3,title:"Sacvia Basic",description:"Some Description",price:300.00,formattedprice:"R 300.00",vat:0,image:'https://via.placeholder.com/150'},
      <ProductDTO>{id:4,title:"Sacvia Advanced",description:"Some Description",price:100.00,formattedprice:"R 100.00",vat:0,image:'https://via.placeholder.com/150'},
      <ProductDTO>{id:5,title:"AAA Response ICD",description:"Some Description",price:200.00,formattedprice:"R 200.00",vat:0,image:'https://via.placeholder.com/150'},
      <ProductDTO>{id:6,title:"Code Red Medical Assist",description:"Some Description",price:300.00,formattedprice:"R 300.00",vat:0,image:'https://via.placeholder.com/150'},
      <ProductDTO>{id:7,title:"AAA Response Basic",description:"Some Description",price:100.00,formattedprice:"R 100.00",vat:0,image:'https://via.placeholder.com/150'},
      <ProductDTO>{id:8,title:"Government Services PWA",description:"Some Description",price:200.00,formattedprice:"R 200.00",vat:0,image:'https://via.placeholder.com/150'},
      <ProductDTO>{id:9,title:"MDDR",description:"Some Description",price:300.00,formattedprice:"R 300.00",vat:0,image:'https://via.placeholder.com/150'},
      <ProductDTO>{id:10,title:"Sample Product",description:"Some Description",price:100.00,formattedprice:"R 100.00",vat:0,image:'https://via.placeholder.com/150'},
      <ProductDTO>{id:11,title:"Sample Product",description:"Some Description",price:200.00,formattedprice:"R 200.00",vat:0,image:'https://via.placeholder.com/150'},
      <ProductDTO>{id:12,title:"Sample Product",description:"Some Description",price:300.00,formattedprice:"R 300.00",vat:0,image:'https://via.placeholder.com/150'},
    ]

}
