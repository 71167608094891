<template>
  <div>
    <CModal :show.sync="modalVisible">
      <CCardBody>
        <CForm>
          <CInput
            v-for="item in el"
            :key="item.key"
            :placeholder="item.placeHolder"                        
            :name="item.key"
            :value.sync="item.value"
          />
        </CForm>
      </CCardBody>
      <template #footer>
        <CButton @click="deleteItem" id="deleteButton" :show.sync="deleteVisible" color="danger">Delete</CButton>
        <CButton @click="saveItem" color="success">Save</CButton>
      </template>
    </CModal>
  </div>
</template>

<style lang="scss">
</style>

<script lang="js">
export default {
  name: "TbEditModal",
  data() {
    return {
      modalVisible: false,              
      el: [],
      deleteVisible: false
    };
  },
  methods: {
    showModal(el, extras = {}, name) {      
      this.el = el;
      this.modalVisible = true;
      this.name = name
      
      const deleteButton = document.getElementById('deleteButton');
      extras?.deleteVisible ? deleteButton?.classList.remove('invisible'): deleteButton?.classList.add('invisible');                  
    },
    saveItem(el) {
      console.log('Save data');
      this.$emit('saveclicked', this.name)
      this.hideModal();
    },
    deleteItem(el) {
      console.log('Save data');      
      
      this.hideModal();
    },
    hideModal() {
      this.modalVisible = false;
    },
  },
};
</script>
