














import {Component, Prop, Vue} from 'vue-property-decorator';
import {TicketDTO} from "@shared_vue/openapi/myvaultapi";


@Component({
  components: {}
})

export default class DeskRequest extends Vue {
  // @Prop(String) readonly description: string | undefined;
  // @Prop({default: 'false'}) readonly active : boolean | undefined; //is this the currently selected one
  // @Prop({default: 'false'}) readonly read : boolean | undefined; //has user read this one
  // @Prop(String) readonly state : string | undefined; //e.g. open
  // @Prop(String) readonly title : string | undefined;
  // @Prop(String) readonly count : string | undefined; //how many in thread
  // @Prop(String) readonly date : string | undefined; //create date
  @Prop({default:[]}) readonly tickets!: TicketDTO[];
  @Prop({default: 'false'}) readonly active! : boolean; //is this the currently selected one
  @Prop({default: 'false'}) readonly read! : boolean; //has user read this one

  get threadLength(){
    return this.tickets?.length??0;
  }
  get mainTicket(){
    return this.tickets[0]??(<TicketDTO>{});
  }
};
