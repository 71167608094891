































































import { vxm } from "@/store";
import { Component, Prop, Vue } from "vue-property-decorator";
import WaitModal from "@/views/widgets/modals/WaitModal.vue";
import BorderlessCard from "@/views/viewcomponents/BorderlessCard.vue";
import CustomCard from "@/views/viewcomponents/CustomCard.vue";
import AssetCard from "@/views/widgets/AssetCard.vue";
import InsuranceCard from "@/views/widgets/InsuranceCard.vue";
import NewAssetModal from "@/views/widgets/modals/NewAssetModal.vue";
import DeviceCard from "@/views/widgets/DeviceCard.vue";
import {
  DeviceDTO,
  AppDTO,
  DeviceApiFactory,
  ProfileApiFactory,
  Configuration,
} from "@shared_vue/openapi/myvaultapi";

import AppCard from "@/views/widgets/AppCard.vue";

@Component({
  components: {
    AppCard,
    DeviceCard,
    AssetCard,
    WaitModal,
    CustomCard,
    BorderlessCard,
    InsuranceCard,
    NewAssetModal,
  },
})

//the layout for each asset page is the same
//only the content and items differ
//so I think it will also be good to lazy-fetch these instead of cacheing so much data all the time
export default class LinkedAppsPage extends Vue {
  private profileStore = vxm.profile;
  private profileApi = ProfileApiFactory(
    <Configuration>{ basePath: process.env.VUE_APP_MYVAULT_API_URL },
    process.env.VUE_APP_MYVAULT_API_URL
  );

  @Prop(String) readonly assetid: string | undefined;

  private title: string = "";
  private blurb: string = "";
  private showWait: boolean = false;
  private showNew: boolean = false;

  // private description: any={};
  private ui = vxm.ui;

  private localDeviceList: Array<DeviceDTO> = [];

  get appList(): Array<AppDTO> {    
    // this.localDeviceList seems empty at this point.

    return <AppDTO[]>this.localDeviceList.map((d) => d.apps).flat();
  }

  mounted() {
    console.log(`asset id: ${this.assetid}`);
    // this.assetList=this.profileStore.assetList.filter(a=>a.assetType?.toLowerCase()==this.assetid?.toLowerCase());
    // this.description = this.descriptions.find(a=>a.id==this.assetid);
    this.profileApi
      .profileGetOptions("AssetType")
      .then((res) => console.log(res));
    this.getDevices(); //note, checking for token is such a common pattern, I need to abstract it somehow for all these
  }

  async getDevices() {
    let deviceApi = DeviceApiFactory(
      <Configuration>{ basePath: process.env.VUE_APP_MYVAULT_API_URL },
      process.env.VUE_APP_MYVAULT_API_URL
    );
    try {
      let result = await deviceApi.deviceGetDevices();
      this.localDeviceList = result.data;
    } catch (e) {
      // just do nothing for now. I'll give this a thing
    }
  }

  private addNew() {
    this.showNew = true;
  }
}
