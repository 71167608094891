



































import {vxm} from "@/store";
import {Component, Vue, Prop, PropSync} from 'vue-property-decorator';
import {AssetDTO} from "@shared_vue/openapi/myvaultapi";


@Component
export default class NewAssetModal extends Vue {
  @Prop(String) readonly title: string | undefined;
  @Prop(String) readonly message: string | undefined;
  @PropSync('showvar', { type: Boolean }) syncedShow!: boolean;
  @PropSync('dismissed', { type: Boolean }) syncedDismiss!: boolean;
  @PropSync('newasset', {  }) syncedAsset!: AssetDTO;
  private ui = vxm.ui;
  private newName: string = '';
  private newDescription: string = '';

  private dismiss(){
    this.$emit('dismissed')
    this.syncedShow=false;
    this.syncedDismiss=true;
  }

}
