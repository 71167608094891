





















import { vxm } from "@/store";
import { Component, Vue } from 'vue-property-decorator';

import GenericGridCard from "@/views/dashcards/GenericGridCard.vue";
import GenericGridAddNewCard from "@/views/dashcards/GenericGridAddNewCard.vue";


@Component({
  components: { GenericGridCard, GenericGridAddNewCard }
})

export default class MyEmergencyPage extends Vue {
  private ui = vxm.ui;

  private cards = [
    // add "hide: true," to hide a card
    // add "cardtype: 'addNewItem'," to add the add new item template.
    {
      id: 1,
      additionalclasses: "backgroundRing",
      title: 'MY CONNECTME EMERGENCY CONTACTS',
      // hide: true, // Example
      description: 'some description',
      image: 'MyEmergency.svg',
      subimage: 'My emergency - connect me emergency contacts.svg',
      height: '45',
      link: '/'
    },
    {
      id: 2,
      additionalclasses: "backgroundRing",
      title: 'MYEMERGENCY PROCEDURE',
      description: 'some description',
      image: 'MyEmergency.svg',
      subimage: 'My emergency - emergency procedure.svg',
      height: '40',
      link: '/'
    },
    {
      id: 3,
      additionalclasses: "backgroundRing",
      title: 'MYCOMMUNITY AIRHORN',
      description: 'some description',
      image: 'MyEmergency.svg',
      subimage: 'My emergency - community airhorn.svg',
      height: '40',
      link: '/'
    },
    {
      id: 4,
      additionalclasses: "backgroundRing",
      title: 'PATHFINDER',
      description: 'some description',
      image: 'MyEmergency.svg',
      subimage: 'My emergency - pathfinder.svg',
      height: '40',
      link: '/'
    },
    {
      id: 5,
      additionalclasses: "backgroundRing",
      title: 'MYINSTANT EMERGENCY QUICK LINKS',
      description: 'some description',
      image: 'MyEmergency.svg',
      subimage: 'My emergency - emergency quicklinks.svg',
      height: '25',
      link: '/'
    },
    {
      id: 6,
      additionalclasses: "backgroundRing",
      title: 'BIOMETRICS',
      description: 'some description',
      image: 'MyEmergency.svg',
      subimage: 'My emergency - biometrics.svg',
      height: '40',
      link: '/'
    },
    {
      id: 7,
      additionalclasses: "backgroundRing",
      title: 'MYMEDIC ALERT CARD',
      description: 'some description',
      image: 'MyEmergency.svg',
      subimage: 'My emergency - medic alert card.svg',
      height: '30',
      link: '/'
    },
    {
      id: 8,
      additionalclasses: "backgroundRing",
      title: 'NEW ITEM / PROVIDER',
      description: 'some description',
      image: 'MyEmergency.svg',
      subimage: 'My emergency - medic alert card.svg',
      height: '40',
      link: '/',
      cardtype: 'addNewItem'
    }
  ]

}
