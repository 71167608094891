

























import {vxm} from "@/store";
import {Component, Vue, Prop} from 'vue-property-decorator';
import {Configuration, ShopApiFactory} from "@shared_vue/openapi/myvaultapi";


@Component
export default class CheckoutSummary extends Vue {

  private ui = vxm.ui;
  private shop = vxm.shop;
  //note these dynamic static assets will likely be served from S3


  private get getCount(){
    return this.shop.ItemCount;
  }

  private get formattedPrice(){
    let total = this.shop.SubscriptionSum;
    let formatter = new Intl.NumberFormat('en-ZA', {
      style: 'currency',
      currency: 'ZAR',
    });
    return formatter.format(total);
  }

  private async pay() {
    //right now I will just mark these as paid and succeed. not sure how we are going to do payments
    let shopApi = ShopApiFactory(<Configuration>{basePath:process.env.VUE_APP_MYVAULT_API_URL},process.env.VUE_APP_MYVAULT_API_URL);
    let newCart = await shopApi.shopCartPaid();
    await this.shop.replaceCart(newCart.data);
    //what now? redirect?
    this.$emit('paid',true)
  }

}
